import React from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import { EditorFormFieldType } from '@humancollective/build-forms'

import EditorTemplate from '../../templates/EditorTemplate'

export interface EditDashboardRouteParams {
  dashboardId: string
}

const EditDashboard: React.FunctionComponent = () => {
  const { dashboardId } = useParams<EditDashboardRouteParams>()
  return (
    <EditorTemplate
      formSchema={[
        {
          name: 'name',
          label: 'Dashboard Name',
          fieldType: EditorFormFieldType.Text,
        },
      ]}
      getQueryRef={() =>
        firebase
          .app('dashboard')
          .firestore()
          .collection('dashboards')
          .doc(dashboardId)
      }
    />
  )
}

export default EditDashboard
