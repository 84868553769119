import get from 'lodash/get'
import ColorHash from 'color-hash'
import { firestore } from 'firebase/app'
import format from 'date-fns/format'

const colorHash = new ColorHash()

interface IconDisplayTemplate {
  background?: string
  backgroundHashPath?: string
  backgroundPath?: string
  abbrPath?: string
  path?: string
}

interface LabelDisplayTemplate {
  path: string
  type?: string
  format?: string
}

export interface DisplayTemplate {
  title?: LabelDisplayTemplate
  subtitle?: LabelDisplayTemplate
  icon?: IconDisplayTemplate
}

const getIconValue = (doc: any, display: IconDisplayTemplate) => {
  const icon: any = {}
  if (display.background) {
    icon.background = display.background
  }
  if (display.backgroundHashPath) {
    const hashValue = get(doc, display.backgroundHashPath)
    const color = colorHash.hex(hashValue)
    if (color) {
      icon.background = color
    }
  }
  if (display.backgroundPath) {
    icon.background = get(doc, display.backgroundPath)
  }
  if (display.abbrPath) {
    icon.abbr = get(doc, display.abbrPath)
  }
  if (display.path) {
    icon.url = get(doc, display.path)
  }
  return icon
}

const getFormattedValue = (doc: any, display: LabelDisplayTemplate) => {
  let value = get(doc, display.path)
  if (value && display.type === 'timestamp') {
    value = (value as firestore.Timestamp).toDate()
    if (display.format) {
      value = format(value, display.format)
    }
  }
  return value
}

export const getDisplayValues = (doc: any, display: DisplayTemplate) => ({
  title: display.title ? getFormattedValue(doc, display.title) : doc.name,
  subtitle: display.subtitle && getFormattedValue(doc, display.subtitle),
  icon: display.icon && getIconValue(doc, display.icon),
})
