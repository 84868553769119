import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import { Popover, PopoverProps } from 'react-tiny-popover'

const showMenuKeyframes = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const StyledPopoverMenuContent = styled.div`
  background: white;
  border-radius: 24px;
  animation: ${showMenuKeyframes} ease 500ms;
  padding: 8px;
  box-shadow: 0 24px 32px #e3e6ec61;
  border: 1px solid #efefef;

  .popover-content {
    &__label {
      font-size: 12px;
      line-height: 12px;
      color: #b2b3bd;
      padding: 12px;
    }
    &__option {
      padding: 12px;
      font-size: 13px;
      line-height: 16px;
      color: #11142d;
      font-weight: 500;
      cursor: pointer;
      border-radius: 16px;
      transition: background-color 250ms ease;

      &.is--danger {
        color: #ff6b6b;
      }

      &:hover {
        background-color: #f8f8f8;
      }
    }
  }
`

export interface PopoverMenuItem {
  type: 'label' | 'option'
  text?: string
  appearance?: 'primary' | 'normal' | 'danger'
  onClick?: () => void | Promise<void>
}

export type PopoverMenuProps = Omit<PopoverProps, 'content'> & {
  items: PopoverMenuItem[]
}

export const PopoverMenu: FunctionComponent<PopoverMenuProps> = ({
  children,
  items,
  ...props
}) => (
  <Popover
    content={
      <StyledPopoverMenuContent>
        {items.map(({ type, text, appearance, onClick }) =>
          type === 'label' ? (
            <h2 className="popover-content__label">{text}</h2>
          ) : (
            <div
              onClick={onClick}
              className={`popover-content__option ${
                appearance === 'danger' ? 'is--danger' : ''
              }`}
            >
              {text}
            </div>
          )
        )}
      </StyledPopoverMenuContent>
    }
    {...props}
  >
    {children}
  </Popover>
)
