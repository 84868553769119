import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Button, Imgix } from '@humancollective/build-ui'

import metricIcon from '../../../assets/jollycons/upward-trend.svg'
import { DashboardCard } from '../DashboardCard'

const StyledExternalLinkCard = styled(DashboardCard)`
  .external-link-card {
    &__icon {
      flex: 1;
    }
  }
`

interface ExternalLinkCardProps {
  action: any
  className?: string
  onClick: () => void
}

export const ExternalLinkCard: FunctionComponent<ExternalLinkCardProps> = ({
  action,
  onClick,
  className,
}) => (
  <StyledExternalLinkCard
    className={className}
    label={action.label}
    icon={metricIcon}
    type="Service"
    bottomContents={
      <>
        <div className="external-link-card__icon">
          <Imgix path={action.icon} options={{ height: 40, width: 200 }} />
        </div>
        <Button onClick={onClick} size="small" appearance="ghost">
          View &rarr;
        </Button>
      </>
    }
  />
)
