import React from 'react'
import styled, { keyframes } from 'styled-components'

import { palette } from '../../theme'

const rotate = keyframes`
  100% { transform: rotate(360deg); }
`

const bounce = keyframes`
  0%, 100% { transform: scale(0); }
  50% { transform: scale(1); }
`

const StyledLoadSpinner = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  animation: ${rotate} 2s infinite linear;

  &:before,
  &:after {
    content: '';
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: ${palette.primary};
    border-radius: 100%;
    animation: ${bounce} 2s infinite ease-in-out;
  }

  &:after {
    top: auto;
    bottom: 0;
    animation-delay: -1s;
  }
`

export const LoadSpinner: React.FunctionComponent = () => <StyledLoadSpinner />
