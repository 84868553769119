import React, { FunctionComponent, useState } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import firebase from 'firebase/app'
import { rhythm } from '@humancollective/build-ui'
import { useAuthedDocument } from '@humancollective/build-firebase'

import { SyncStatus } from '../SyncStatus'
import { useSyncedValue } from '../../utils/useSyncedValue'
import { EditorFormFieldProps, EditorSyncStatus } from '../../types'

const SyledSyncedDraftFormField = styled.div`
  margin-bottom: ${rhythm()}px;
`

export interface SyncedDraftFormFieldProps extends EditorFormFieldProps {
  name: string
  Component: FunctionComponent<any>
}

export const SyncedDraftFormField: FunctionComponent<SyncedDraftFormFieldProps> = ({
  values,
  documentRef,
  name,
  Component,
  ...rest
}) => {
  const [focused, setFocused] = useState(false)

  const draftPath = `${documentRef.firestore.app.name}/${documentRef.path}`
  const draftRef = firebase
    .app('dashboard')
    .firestore()
    .collection('drafts')
    .doc(draftPath)

  const draft = useAuthedDocument(draftRef)

  const { status, value, setValue } = useSyncedValue({
    name,
    documentRef: draftRef,
    initialValue: get({ ...values, ...draft }, name),
  })

  return (
    <SyledSyncedDraftFormField>
      <Component
        values={values}
        documentRef={documentRef}
        value={value}
        onChangeValue={setValue}
        focused={focused}
        setFocused={setFocused}
        {...rest}
      />
      <SyncStatus
        status={status}
        show={focused || status !== EditorSyncStatus.Live}
      />
    </SyledSyncedDraftFormField>
  )
}
