import { useState, useRef, useCallback } from 'react'
import { v4 as uuid } from 'uuid'

const DEBOUNCE_DURATION = 300

export const useLoadingStack = () => {
  const loadingIndex = useRef<string[]>([])
  const [loading, setLoading] = useState(false)
  const loadingTimeout = useRef<NodeJS.Timeout>()

  const updateLoading = useCallback(() => {
    if (loadingIndex.current.length === 0) {
      setLoading(false)
    }
  }, [loadingIndex])

  const stopLoading = useCallback(
    (id: string) => {
      loadingIndex.current = loadingIndex.current.filter(v => v !== id)
      if (loadingTimeout.current) {
        clearTimeout(loadingTimeout.current)
      }
      loadingTimeout.current = setTimeout(updateLoading, DEBOUNCE_DURATION)
    },
    [updateLoading]
  )

  const trackLoading = useCallback(() => {
    setLoading(true)
    const id = uuid()
    loadingIndex.current = [...loadingIndex.current, id]
    return (isComplete: boolean = true) => {
      if (isComplete) {
        stopLoading(id)
      }
    }
  }, [loadingIndex, stopLoading])

  return {
    loading,
    trackLoading,
  }
}
