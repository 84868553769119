import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import RequestPage from './Request'

export const requestRoute = () => `/request/`

const RequestRouter: FunctionComponent = () => (
  <Switch>
    <Route exact path={requestRoute()}>
      <RequestPage />
    </Route>
  </Switch>
)

export default RequestRouter
