import { useState } from 'react'

interface UsePaginationArgs {
  data: any[] | null
  itemsPerPage?: number
  initialPage?: number
}

export const usePagination = ({
  data,
  itemsPerPage = 10,
  initialPage = 1,
}: UsePaginationArgs) => {
  const [index, setIndex] = useState(initialPage)

  const cursor = (index - 1) * itemsPerPage
  const count = Math.ceil((data?.length || 1) / itemsPerPage)

  const displayPagination = count > 1
  const pageData = (data || []).slice(cursor, cursor + itemsPerPage)

  return {
    cursor,
    count,
    pageData,
    displayPagination,
    index,
    setIndex,
  }
}
