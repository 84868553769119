import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import EditPage, { EditPostRouteParams } from './Edit'
import ListPage, { ListPostsRouteParams } from './List'

export const listPostsRoute = ({
  dashboardId,
  collectionId,
}: ListPostsRouteParams) =>
  dashboardId ? `/d/${dashboardId}/p/${collectionId}` : `/p/${collectionId}/`

export const editPostRoute = ({
  dashboardId,
  collectionId,
  docId,
}: EditPostRouteParams) =>
  dashboardId
    ? `/d/${dashboardId}/p/${collectionId}/d/${docId}`
    : `/p/${collectionId}/d/${docId}`

const PostsRouter: FunctionComponent = () => (
  <Switch>
    <Route
      exact
      path={[
        editPostRoute({
          collectionId: ':collectionId',
          docId: ':docId',
        }),
        editPostRoute({
          dashboardId: ':dashboardId',
          collectionId: ':collectionId',
          docId: ':docId',
        }),
      ]}
    >
      <EditPage />
    </Route>
    <Route
      exact
      path={[
        listPostsRoute({
          collectionId: ':collectionId',
        }),
        listPostsRoute({
          dashboardId: ':dashboardId',
          collectionId: ':collectionId',
        }),
      ]}
    >
      <ListPage />
    </Route>
  </Switch>
)

export default PostsRouter
