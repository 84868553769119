import { useContext, useEffect, useState } from 'react'
import { UIContext } from './UIContext'

export const useLoading = (...dependencies: boolean[]) => {
  const { trackLoading } = useContext(UIContext)
  const [handleLoaded, setHandleLoaded] = useState<() => void>()

  useEffect(() => {
    if (trackLoading) {
      setHandleLoaded(trackLoading())
    }
  }, [trackLoading])

  useEffect(() => {
    if (handleLoaded) {
      if (!dependencies.includes(false)) {
        handleLoaded()
      }
      return handleLoaded
    }
    return () => {}
    // eslint-disable-next-line
  }, [handleLoaded, ...dependencies])

  return {}
}
