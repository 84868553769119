import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import WelcomePage from './Welcome'

export const welcomeRoute = () => `/welcome/`

const WelcomeRouter: FunctionComponent = () => (
  <Switch>
    <Route exact path={welcomeRoute()}>
      <WelcomePage />
    </Route>
  </Switch>
)

export default WelcomeRouter
