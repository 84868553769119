import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { parse } from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Pagination,
  rhythm,
  usePagination,
  useSearch,
  Breadcrumbs,
} from '@humancollective/build-ui'

export interface SharedListTemplateProps {
  breadcrumbs?: { label: string; to?: string }[]
  renderItem: (item: any) => React.ReactNode
  onAddItem?: () => void
  controls?: React.ReactNode
}

export interface SearchableTemplate {
  searchKeys?: string[]
}

export interface TemplateData {
  data?: any[] | null
  drafts?: any[] | null
}

const StyledListTemplate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-top: ${rhythm()}px;

  .list-template {
    &__controls {
      padding-bottom: 12px;
      &__flex {
        display: flex;
      }
    }
    &__search {
      flex: 1;
    }
    &__contents {
      flex: 1;
      border-top: 1px solid #f5f5f5;
    }
    &__search-container {
      display: flex;
      align-items: center;
    }
    &__search-icon {
      margin-right: 8px;
    }
    &__pagination-container {
      display: flex;
      justify-content: center;
    }
  }
`

export const ListTemplate: FunctionComponent<
  SharedListTemplateProps & SearchableTemplate & TemplateData
> = ({
  renderItem,
  data = [],
  drafts = [],
  controls,
  searchKeys,
  breadcrumbs,
}) => {
  const location = useLocation()
  const history = useHistory()
  const { pathname } = location
  const [queryLength, setQueryLength] = useState(0)

  const query = parse(location.search)
  const page = Number(query.page) || 1

  const combinedData = useMemo(() => {
    const combined = []
    const dataArr = data ?? []
    const draftsArr = drafts ?? []

    for (const item of draftsArr) {
      const matchData = dataArr.find(({ id }) => id === item.id)
      if (!matchData) {
        combined.push(item)
      }
    }
    for (const item of dataArr) {
      const matchDraft = draftsArr.find(({ id }) => id === item.id) ?? {}
      combined.push({ ...item, ...matchDraft })
    }
    return combined
  }, [drafts, data])

  const search = useSearch({
    data: combinedData,
    keys: searchKeys || [],
  })

  const pagination = usePagination({
    data: search.results,
    itemsPerPage: 20,
    initialPage: page,
  })

  useEffect(() => {
    if (page !== pagination.index) {
      pagination.setIndex(page)
    }
    // we get an eslint error for pagination missing from this hook,
    // but if we add it as a dep it breaks pagination
    // eslint-disable-next-line
  }, [page])

  useEffect(() => {
    if (queryLength !== search.query.length && page !== 1) {
      setQueryLength(search.query.length)
      history.push(`${pathname}?page=1`)
    }
    // we get an eslint error for pagination missing from this hook,
    // but if we add it as a dep it breaks pagination
    // eslint-disable-next-line
  }, [search.query])

  return data ? (
    <StyledListTemplate>
      {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
      <br />
      <div className="list-template__controls">
        <div className="list-template__controls__flex">
          {/* {search.isReady && (
            <TemplateSearchBar
              className="list-template__search"
              query={search.query}
              setQuery={search.setQuery}
            />
          )} */}
          {controls}
        </div>
      </div>
      <div className="list-template__contents">
        {pagination.pageData.map(renderItem)}
      </div>
      {pagination.displayPagination && (
        <div className="list-template__pagination-container">
          <Pagination
            count={pagination.count}
            index={pagination.index}
            onChange={(index) => {
              if (index !== page) {
                history.push(`${pathname}?page=${index}`)
              }
            }}
          />
        </div>
      )}
    </StyledListTemplate>
  ) : null
}
