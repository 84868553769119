import { GroupFieldProps } from '../fields/GroupField'
import { ListFieldProps } from '../fields/ListField'
import { SwitchFieldProps } from '../fields/SwitchField'
import { SelectFieldProps } from '../fields/SelectField'
import { DateTimeFieldProps } from '../fields/DateTimeField'
import { ImageFieldProps } from '../fields/ImageField'
import { InputFieldProps } from '../fields/InputField'
import { ReferenceFieldProps } from '../fields/ReferenceField'
import { RelationshipFieldProps } from '../fields/RelationshipField'
import { ExternalFieldProps } from '../fields/ExternalField'
import { HtmlFieldProps } from '../fields/HtmlField'

export enum EditorFormFieldType {
  ImportExport = 'IMPORT_EXPORT',
  External = 'EXTERNAL',
  DynamicList = 'DYNAMIC_LIST',
  Relationship = 'RELATIONSHIP',
  Image = 'IMAGE',
  MultiSelect = 'MULTI_SELECT',
  Select = 'SELECT',
  Reference = 'REFERENCE',
  Switch = 'SWITCH',
  Date = 'DATE',
  Group = 'GROUP',
  Text = 'TEXT',
  Color = 'COLOR',
  Number = 'NUMBER',
  Hidden = 'HIDDEN',
  Html = 'HTML',
}

export enum EditorSyncStatus {
  Loading = 'LOADING',
  Live = 'LIVE',
  Syncing = 'SYNCING',
  Modified = 'MODIFIED',
  Error = 'ERROR',
}

interface FieldMetadata {
  name: string
}

export type EditorFormField = FieldMetadata &
  (
    | ({ fieldType: EditorFormFieldType.Image } & ImageFieldProps)
    | ({ fieldType: EditorFormFieldType.Switch } & SwitchFieldProps)
    | ({ fieldType: EditorFormFieldType.Date } & DateTimeFieldProps)
    | ({ fieldType: EditorFormFieldType.Group } & GroupFieldProps)
    | ({ fieldType: EditorFormFieldType.Html } & HtmlFieldProps)
    | ({ fieldType: EditorFormFieldType.Select } & SelectFieldProps)
    | ({ fieldType: EditorFormFieldType.Reference } & ReferenceFieldProps)
    | ({ fieldType: EditorFormFieldType.Text } & InputFieldProps)
    | ({ fieldType: EditorFormFieldType.Number } & InputFieldProps)
    | ({ fieldType: EditorFormFieldType.DynamicList } & ListFieldProps)
    | ({ fieldType: EditorFormFieldType.Relationship } & RelationshipFieldProps)
    | ({ fieldType: EditorFormFieldType.External } & ExternalFieldProps)
  )

export interface EditorFormFieldProps {
  values: any
  documentRef: firebase.firestore.DocumentReference
}
