import React, { useRef, useState } from 'react'
import firebase, { storage } from 'firebase/app'
import { v4 as uuid } from 'uuid'
import joinUrl from 'url-join'
import { useAuth } from '@humancollective/build-firebase'
import { Button } from '@humancollective/build-ui'

export interface ImageUploader {
  app?: firebase.app.App
  baseUrl?: string
  onUploadSuccess: (filePath: string) => void
}

export const ImageUploader: React.FunctionComponent<ImageUploader> = ({
  app,
  baseUrl,
  onUploadSuccess,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadProgress, setUploadProgress] = useState(0)

  const firebaseUser = useAuth(app)

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleError = (error: Error) => {
    console.log('Error', error)
    setUploadProgress(0)
  }

  const handleSuccess = (filePath: string) => {
    setUploadProgress(0)
    console.log()
    onUploadSuccess(joinUrl(baseUrl || '', filePath))
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const fileToUpload: undefined | File = files ? files[0] : undefined

    if (!firebaseUser) {
      throw new Error('attempted upload without a firebase user')
    }

    if (fileToUpload) {
      const fileHash = uuid()
      // Sets the file name to a random hash & uses the original image extension
      const filePath = `uploads/${firebaseUser.uid}/${fileHash}`

      const uploadTask = storage(app)
        .ref(`/imgix/${filePath}`)
        .put(fileToUpload)

      uploadTask.on(
        storage.TaskEvent.STATE_CHANGED,
        progress => {
          const uploadPercent =
            (progress.bytesTransferred / progress.totalBytes) * 100
          setUploadProgress(Number(uploadPercent.toFixed(0)))
        },
        handleError,
        () => handleSuccess(filePath)
      )
    }
  }

  return (
    <div>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
      />
      <Button size="small" appearance="outline" onClick={onClick}>
        {uploadProgress > 0 ? `Uploading ${uploadProgress}%...` : 'Upload'}
      </Button>
    </div>
  )
}
