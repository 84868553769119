import { useState, useEffect, useMemo } from 'react'
import { firestore } from 'firebase/app'

import { CollectionRefGetter, makeCollectionRef } from '../utils/makeRef'
import { useAuth } from './useAuth'

export const useAuthedCollection = <T>(
  collectionRef:
    | CollectionRefGetter
    | firestore.CollectionReference
    | firestore.Query,
  dependencies: any[] = [],
) => {
  const [value, setValue] = useState<(T & { id: string })[]>()
  const [listener, setListener] = useState({ unsubscribe: () => {} })

  const app = useMemo(() => makeCollectionRef(collectionRef).firestore.app, [
    collectionRef,
  ])
  const firebaseUser = useAuth(app)

  useEffect(() => {
    listener.unsubscribe()
    if (firebaseUser) {
      const off = makeCollectionRef(
        collectionRef,
        firebaseUser.uid,
        dependencies,
      ).onSnapshot(onUpdate)
      setListener({ unsubscribe: off })
      return off
    }
    return () => {}
  }, [firebaseUser, ...dependencies])

  const onUpdate = async (querySnap: firestore.QuerySnapshot) => {
    const allDocs = querySnap.docs
    const nextValue: (T & { id: string })[] = []
    for (const doc of allDocs) {
      nextValue.push({
        id: doc.id,
        ...(doc.data() as T),
      })
    }
    setValue(nextValue)
  }

  return value
}
