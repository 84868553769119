import { useEffect, useState, useMemo } from 'react'
import { firestore } from 'firebase/app'

import { CollectionRefGetter, makeCollectionRef } from '../utils/makeRef'
import { useAuth } from './useAuth'

export const useAuthedCollectionCount = (
  collectionRef:
    | CollectionRefGetter
    | firestore.CollectionReference
    | firestore.Query,
  dependencies: any[] = [],
) => {
  const [value, setValue] = useState<number>()
  const [listener, setListener] = useState({ unsubscribe: () => {} })

  const app = useMemo(() => makeCollectionRef(collectionRef).firestore.app, [
    collectionRef,
  ])
  const firebaseUser = useAuth(app)

  useEffect(() => {
    listener.unsubscribe()
    if (firebaseUser) {
      const off = makeCollectionRef(
        collectionRef,
        firebaseUser.uid,
        dependencies,
      ).onSnapshot(onUpdate)
      setListener({ unsubscribe: off })
      return off
    }
    return () => {}
  }, [firebaseUser])

  const onUpdate = async (querySnap: firestore.QuerySnapshot) => {
    setValue(querySnap.size ?? 0)
  }

  return value
}
