import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import { useLoading, formatCurrency } from '@humancollective/build-ui'
import {
  useAuthedDocument,
  buildCollectionRef,
} from '@humancollective/build-firebase'

import { AuthContext } from '../../contexts/AuthContext'
import { CollectionMetricTemplate } from '../../templates/MetricTemplate/CollectionList'
import { MetricTemplateRow } from '../../templates/MetricTemplate/MetricTemplateRow'

export interface ViewMetricRouteParams {
  dashboardId?: string
  metricId: string
}

const ViewMetric: React.FunctionComponent = () => {
  const { metricId } = useParams<ViewMetricRouteParams>()
  const { getApp } = useContext(AuthContext)

  const collection = useAuthedDocument(
    firebase
      .app('dashboard')
      .firestore()
      .collection('apps')
      .doc(metricId)
  )

  const app = useMemo(() => (collection ? getApp(collection) : undefined), [
    collection,
    getApp,
  ])
  useLoading(!!app)

  return app ? (
    <CollectionMetricTemplate
      getQueryRef={() =>
        buildCollectionRef(collection?.firebasePath || '', undefined, app)
      }
      renderItem={(doc: any) => (
        <MetricTemplateRow
          key={doc.id}
          label={doc.name}
          value={formatCurrency(doc.revenue)}
        />
      )}
    />
  ) : null
}

export default ViewMetric
