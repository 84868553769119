import React, { FunctionComponent, useContext } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { Button, rhythm, formatCurrency } from '@humancollective/build-ui'

import metricIcon from '../../../assets/jollycons/upward-trend.svg'
import { AuthContext } from '../../../contexts/AuthContext'
import { DashboardCard } from '../DashboardCard'
import { LoadingCard } from '../LoadingCard'

const StyledTopThreeMetricCard = styled(DashboardCard)`
  .metric-card {
    &__shows {
      margin-top: ${rhythm(1 / 2)}px;
      width: 100%;
      td:nth-child(2) {
        text-align: right;
      }
    }
    &__bottom-controls {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: ${rhythm(1 / 2)}px;
    }
  }
`

interface TopThreeMetricCardProps {
  className?: string
  metric: any
  onClick?: () => void
}

const buildCollectionRef = (path: string, app?: firebase.app.App) => {
  const firebaseApp = app || firebase.app()
  let ref = firebaseApp.firestore() as unknown
  const parts = path.split('.')
  parts.forEach((part, index) => {
    const type = index % 2 === 0 ? 'collection' : 'doc'
    ref = (ref as any)[type](part)
  })
  return ref as
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query
}

export const TopThreeMetricCard: FunctionComponent<TopThreeMetricCardProps> = ({
  metric,
  className,
  onClick,
}) => {
  const { getApp } = useContext(AuthContext)

  const app = getApp(metric)

  const docs = useAuthedCollection(
    buildCollectionRef(metric.firebasePath, app)
      .orderBy(metric.valuePath, 'desc')
      .limit(3)
  )

  const isLoading = typeof docs === 'undefined'

  return isLoading ? (
    <LoadingCard
      className={className}
      label={metric.label}
      icon={metricIcon}
      type="Metric"
    />
  ) : (
    <StyledTopThreeMetricCard
      className={className}
      label={metric.label}
      icon={metricIcon}
      type="Metric"
      middleContents={
        <table className="metric-card__shows">
          <tbody>
            {(docs as any[]).map(({ id, name, revenue }) => (
              <tr key={id}>
                <td>{name}</td>
                <td>{formatCurrency(revenue)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }
      bottomContents={
        <div className="metric-card__bottom-controls">
          <hr />
          <Button size="small" appearance="ghost" onClick={onClick}>
            View All &rarr;
          </Button>
        </div>
      }
    />
  )
}
