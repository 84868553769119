import { useState } from 'react'

export const useStateDictionary = <T = any>() => {
  const [values, setValues] = useState<{ [id: string]: T }>({})

  const get = (id: string) => values[id]

  const set = (id: string, value: T) => {
    setValues(currentValues => ({ ...currentValues, [id]: value }))
  }

  const remove = (id: string) => {
    const nextValues = { ...values }
    delete nextValues[id]
    setValues(nextValues)
  }

  const clear = () => {
    setValues({})
  }

  return { values, get, set, remove, clear }
}
