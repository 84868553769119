import React from 'react'
import ReactSelect from 'react-select'
import { Label, rhythm, BASE_RADIUS } from '@humancollective/build-ui'

export interface SelectFieldProps {
  value: any
  label?: string
  required?: boolean
  helperText?: string
  className?: string
  multiple?: boolean
  options: { label: string; value: string }[]
  onChangeValue: (value: any) => void | Promise<void>
  labelKey?: string
}

export const SelectField: React.FunctionComponent<SelectFieldProps> = ({
  value,
  onChangeValue,
  label,
  options,
  className,
  multiple,
}) => {
  const handleChange = (selection: any) => {
    if (multiple) {
      onChangeValue(selection.map(({ value }: any) => value))
    } else {
      onChangeValue(selection.value)
    }
  }

  let expandedValue
  if (multiple) {
    expandedValue = (value || []).map((v: string) =>
      options.find(o => o.value === v)
    )
  } else {
    expandedValue = options.find(o => o.value === value) || null
  }

  return (
    <div className={className}>
      {label && <Label className="formik-select__label">{label}</Label>}
      <ReactSelect
        value={expandedValue}
        options={options}
        onChange={handleChange}
        isMulti={multiple}
        styles={{
          control: styles => ({
            ...styles,
            borderColor: '#ced4db',
            minHeight: rhythm(5 / 3),
            borderRadius: BASE_RADIUS,
          }),
          multiValue: styles => ({
            ...styles,
            backgroundColor: '#f8f9fb',
            border: '1px solid #ced4db',
            borderRadius: BASE_RADIUS,
          }),
          valueContainer: styles => ({
            ...styles,
            paddingTop: 2,
            paddingBottom: 2,
          }),
        }}
      />
    </div>
  )
}
