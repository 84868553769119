import { createGlobalStyle } from 'styled-components'

import { typography } from './typography'
import {
  palette,
  Palette,
  FontStack,
  BASE_FONT_SIZE,
  BASE_FONT_WEIGHT,
  BASELINE_HEIGHT,
  STRONG_FONT_WEIGHT,
} from '../theme'

const SystemFontCSS = `
  /*! system-font.css v2.0.2 | CC0-1.0 License | github.com/jonathantneal/system-font-css */

  @font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 300;
    src: local(".SFNS-Light"), local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Segoe UI Light"), local("Ubuntu Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
  }

  @font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 300;
    src: local(".SFNS-LightItalic"), local(".SFNSText-LightItalic"), local(".HelveticaNeueDeskInterface-Italic"), local(".LucidaGrandeUI"), local("Segoe UI Light Italic"), local("Ubuntu Light Italic"), local("Roboto-LightItalic"), local("DroidSans"), local("Tahoma");
  }

  @font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 400;
    src: local(".SFNS-Regular"), local(".SFNSText-Regular"), local(".HelveticaNeueDeskInterface-Regular"), local(".LucidaGrandeUI"), local("Segoe UI"), local("Ubuntu"), local("Roboto-Regular"), local("DroidSans"), local("Tahoma");
  }

  @font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 400;
    src: local(".SFNS-Italic"), local(".SFNSText-Italic"), local(".HelveticaNeueDeskInterface-Italic"), local(".LucidaGrandeUI"), local("Segoe UI Italic"), local("Ubuntu Italic"), local("Roboto-Italic"), local("DroidSans"), local("Tahoma");
  }

  @font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 500;
    src: local(".SFNS-Medium"), local(".SFNSText-Medium"), local(".HelveticaNeueDeskInterface-MediumP4"), local(".LucidaGrandeUI"), local("Segoe UI Semibold"), local("Ubuntu Medium"), local("Roboto-Medium"), local("DroidSans-Bold"), local("Tahoma Bold");
  }

  @font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 500;
    src: local(".SFNS-MediumItalic"), local(".SFNSText-MediumItalic"), local(".HelveticaNeueDeskInterface-MediumItalicP4"), local(".LucidaGrandeUI"), local("Segoe UI Semibold Italic"), local("Ubuntu Medium Italic"), local("Roboto-MediumItalic"), local("DroidSans-Bold"), local("Tahoma Bold");
  }

  @font-face {
    font-family: system-ui;
    font-style: normal;
    font-weight: 700;
    src: local(".SFNS-Bold"), local(".SFNSText-Bold"), local(".HelveticaNeueDeskInterface-Bold"), local(".LucidaGrandeUI"), local("Segoe UI Bold"), local("Ubuntu Bold"), local("Roboto-Bold"), local("DroidSans-Bold"), local("Tahoma Bold");
  }

  @font-face {
    font-family: system-ui;
    font-style: italic;
    font-weight: 700;
    src: local(".SFNS-BoldItalic"), local(".SFNSText-BoldItalic"), local(".HelveticaNeueDeskInterface-BoldItalic"), local(".LucidaGrandeUI"), local("Segoe UI Bold Italic"), local("Ubuntu Bold Italic"), local("Roboto-BoldItalic"), local("DroidSans-Bold"), local("Tahoma Bold");
  }
`

export const GlobalCSS = createGlobalStyle`
  ${SystemFontCSS};

  /**
   * This is a mix of our own styling and rules taken from normalize.css
   * Normalize can be found at https://github.com/necolas/normalize.css/
   */

  .grecaptcha-badge { 
    visibility: hidden;
  }

  * { box-sizing: border-box; }

  html {
    line-height: ${BASELINE_HEIGHT}px;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    font-family: ${FontStack.UI};
    font-size: ${BASE_FONT_SIZE}px;
    font-weight: ${BASE_FONT_WEIGHT};
    background-color: #f8f9fb;
    color: ${Palette.OffBlack};
  }

  main { display: block; }

  a {
    background-color: transparent;
    color: ${palette.primary};
    text-underline-position: under;
  }

  abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
  }

  p, h1, h2, h3, h4, h5, h6, h7, blockquote, ul, ol, dl, menu {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  hr {
    width: 100%;
    height: 1px;
    border: none;
    background: ${Palette.OffWhite};
  }

  h1 { ${typography.h1}; }
  h2 { ${typography.h2}; }
  h3 { ${typography.h3}; }
  h4 { ${typography.h4}; }
  h5 { ${typography.h5}; }
  h6 { ${typography.h6}; }

  p { ${typography.p}; }

  blockquote { ${typography.blockquote}; }

  pre, kbd, samp { ${typography.pre}; }
  code { ${typography.code}; }
  big { ${typography.big}; }
  small { ${typography.small}; }

  ul, ol, dl, menu {
    ${typography.list};
  }

  menu { list-style: none; }

  img { border-style: none; }

  button,
  input,
  optgroup,
  select,
  textarea {
    ${typography.formElement};
  }

  /**
   * Show the overflow in IE.
   */

  button, input { overflow: visible; }

  /**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   */

  button, select { text-transform: none; }

  /**
   * Correct the inability to style clickable types in iOS and Safari.
   */

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  /**
   * Remove the inner border and padding in Firefox.
   */

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /**
   * Restore the focus styles unset by the previous rule.
   */

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  /**
   * Correct the padding in Firefox.
   */

  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }

  /**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from 'fieldset' elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    'fieldset' elements in all browsers.
   */

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }

  /**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */

  progress {
    vertical-align: baseline;
  }

  /**
   * Remove the default vertical scrollbar in IE 10+.
   */

  textarea {
    overflow: auto;
  }

  /**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */

  [type="checkbox"],
  [type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }

  /**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  /**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */

  [type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }

  /**
   * Remove the inner padding in Chrome and Safari on macOS.
   */

  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  /**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to 'inherit' in Safari.
   */

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  sub { bottom: -0.25em; }
  sup { top: -0.5em; }

  b, strong { font-weight: ${STRONG_FONT_WEIGHT}; }

  /*
  * Add the correct display in Edge, IE 10+, and Firefox.
  */

  details {
    display: block;
  }

  /*
  * Add the correct display in all browsers.
  */

  summary {
    display: list-item;
  }

  /**
   * Add the correct display in IE 10+.
   */

  template { display: none; }

  /**
   * Add the correct display in IE 10.
   */

  [hidden] { display: none; }

  fieldset {
    padding: 0;
    border: 0;
  }
`
