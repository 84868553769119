import React, { FunctionComponent, useContext } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import sumBy from 'lodash/sumBy'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { rhythm, formatCurrency } from '@humancollective/build-ui'

import metricIcon from '../../../assets/jollycons/upward-trend.svg'
import { AuthContext } from '../../../contexts/AuthContext'
import { DashboardCard } from '../DashboardCard'
import { LoadingCard } from '../LoadingCard'

const StyledTotalMetricCard = styled(DashboardCard)`
  .total-metric-card {
    &__total {
      text-align: right;
      width: 100%;
      line-height: ${rhythm()}px;
      margin-top: ${rhythm(1 / 2)}px;
    }
  }
`

interface TotalMetricCardProps {
  metric: any
  className?: string
}

const buildCollectionRef = (path: string, app?: firebase.app.App) => {
  const firebaseApp = app || firebase.app()
  let ref = firebaseApp.firestore() as unknown
  const parts = path.split('.')
  parts.forEach((part, index) => {
    const type = index % 2 === 0 ? 'collection' : 'doc'
    ref = (ref as any)[type](part)
  })
  return ref as
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query
}

export const TotalMetricCard: FunctionComponent<TotalMetricCardProps> = ({
  metric,
  className,
}) => {
  const { getApp } = useContext(AuthContext)

  const app = getApp(metric)
  const getQueryRef = () => buildCollectionRef(metric.firebasePath, app)
  const docs = useAuthedCollection(getQueryRef)

  const isLoading = typeof docs === 'undefined'

  return isLoading ? (
    <LoadingCard
      className={className}
      label={metric.label}
      icon={metricIcon}
      type="Metric"
    />
  ) : (
    <StyledTotalMetricCard
      className={className}
      label={metric.label}
      icon={metricIcon}
      type="Metric"
      bottomContents={
        <h2 className="total-metric-card__total">
          {formatCurrency(sumBy(docs, metric.valuePath))}
        </h2>
      }
    />
  )
}
