import { useState, useEffect, useMemo } from 'react'
import { firestore } from 'firebase/app'
import isEqual from 'lodash/isEqual'

import { DocumentRefGetter, makeDocumentRef } from '../utils/makeRef'
import { useAuth } from './useAuth'

export const useAuthedDocument = <T = any>(
  documentRef: DocumentRefGetter | firestore.DocumentReference,
  dependencies: any[] = [],
) => {
  const [value, setValue] = useState<T | null>()
  const [listener, setListener] = useState({ unsubscribe: () => {} })
  const [localDependencies, setLocalDependencies] = useState(dependencies)

  useEffect(() => {
    if (!isEqual(dependencies, localDependencies)) {
      setLocalDependencies([...dependencies])
    }
  }, dependencies)

  const app = useMemo(() => makeDocumentRef(documentRef).firestore.app, [
    documentRef,
  ])
  const firebaseUser = useAuth(app)

  useEffect(() => {
    listener.unsubscribe()
    if (firebaseUser) {
      const off = makeDocumentRef(
        documentRef,
        firebaseUser.uid,
        dependencies,
      ).onSnapshot(onUpdate)
      setListener({ unsubscribe: off })
      return off
    }
    return () => {}
  }, [firebaseUser, localDependencies])

  const onUpdate = (doc: firestore.DocumentSnapshot) => {
    const nextValue = (doc.data() as T) ?? ({} as T)
    setValue(nextValue)
  }

  return value
}
