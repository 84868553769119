import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import ListPage, { ListFormResponsesRouteParams } from './List'
import ViewPage, {ViewFormResponseRouteParams} from './View'

export const listFormResponsesRoute = ({
  dashboardId,
  accountId,
}: ListFormResponsesRouteParams) =>
dashboardId ? `/dashboard/${dashboardId}/account/${accountId}` : `/account/${accountId}`

export const viewFormResponseRoute = ({
  dashboardId,
  accountId,
  responseId,
}: ViewFormResponseRouteParams) =>
  dashboardId ? `/dashboard/${dashboardId}/account/${accountId}/response/${responseId}` : `/account/${accountId}/response/${responseId}`

const FormsRouter: FunctionComponent = () => (
  <Switch>
    <Route
      exact
      path={[
        listFormResponsesRoute({
          accountId: ':accountId',
        }),
        listFormResponsesRoute({
          dashboardId: ':dashboardId',
          accountId: ':accountId',
        }),
      ]}
    >
      <ListPage />
    </Route>
    <Route
      exact
      path={[
        viewFormResponseRoute({
          accountId: ':accountId',
          responseId: ':responseId',
        }),
        viewFormResponseRoute({
          dashboardId: ':dashboardId',
          accountId: ':accountId',
          responseId: ':responseId',
        }),
      ]}
    >
      <ViewPage />
    </Route>
  </Switch>
)

export default FormsRouter
