import React from 'react'
import { app } from 'firebase/app'
import { useParams } from 'react-router-dom'
import { EditorFormFieldType } from '@humancollective/build-forms'

import EditorTemplate from '../../templates/EditorTemplate'

const EditDashboardUser: React.FunctionComponent = () => {
  const { userId } = useParams()

  return (
    <EditorTemplate
      getQueryRef={() =>
        app('dashboard').firestore().collection('users').doc(userId)
      }
      formSchema={[
        {
          label: 'Name',
          name: 'name',
          fieldType: EditorFormFieldType.Text,
        },
        {
          label: 'Avatar',
          name: 'avatar',
          fieldType: EditorFormFieldType.Image,
          baseUrl: 'https://human-build.imgix.net/',
        },
      ]}
    />
  )
}

export default EditDashboardUser
