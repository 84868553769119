import React, { FunctionComponent } from 'react'

interface IconProps {
  className?: string
}

export const IconRightCarat: FunctionComponent<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="M.293.293A1 1 0 0 1 1.613.21l.094.083 4 4a1 1 0 0 1 .083 1.32l-.083.094-4 4A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707A1 1 0 0 1 .21.387L.293.293Z"
    />
  </svg>
)
