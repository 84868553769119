import React from 'react'
import { Route } from 'react-router-dom'

import SignOutPage from './SignOut'

const AuthRoutes = () => (
  <Route path="/logout/">
    <SignOutPage />
  </Route>
)

export default AuthRoutes
