import React, { FunctionComponent, useEffect } from 'react'
import { app } from 'firebase/app'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { useLoading } from '@humancollective/build-ui'
import { useHistory } from 'react-router-dom'

import { viewDashboardRoute } from './dashboards'
import { welcomeRoute } from './welcome'

const HomePage: FunctionComponent = () => {
  const history = useHistory()
  const dashboards = useAuthedCollection((uid) =>
    app('dashboard')
      .firestore()
      .collection('permissions')
      .doc(uid)
      .collection('dashboards')
  )
  useLoading(!!dashboards)

  useEffect(() => {
    if (dashboards) {
      if (dashboards.length > 0) {
        history.push(viewDashboardRoute({ dashboardId: dashboards[0].id }))
      } else {
        history.push(welcomeRoute())
      }
    }
  }, [dashboards, history])

  return <></>
}

export default HomePage
