import React from 'react'

import { SyncedFormField } from '../components/SyncedFormField'
import { SyncedDraftFormField } from '../components/SyncedDraftFormField'
import { EditorFormField, EditorFormFieldProps } from '../types'
import { getFieldComponentByType } from './fieldComponentByType'

type FieldValue = EditorFormField & EditorFormFieldProps & { name: string }

export const getFormField = (
  { fieldType, name, ...rest }: FieldValue,
  index: number
) => {
  const Component = getFieldComponentByType(fieldType)
  return (
    <Component
      key={name || `editor-form-field--${index}`}
      name={name}
      {...rest}
    />
  )
}

export const getSyncedFormField = (
  { fieldType, name, ...rest }: FieldValue,
  index: number
) => (
  <SyncedFormField
    key={name || `editor-form-field--${index}`}
    name={name}
    Component={getFieldComponentByType(fieldType)}
    {...rest}
  />
)

export const getSyncedDraftFormField = (
  { fieldType, name, ...rest }: FieldValue,
  index: number
) => (
  <SyncedDraftFormField
    key={name || `editor-form-field--${index}`}
    name={name}
    Component={getFieldComponentByType(fieldType)}
    {...rest}
  />
)
