import { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import { firestore } from 'firebase/app'

import { EditorSyncStatus } from '../types'

export const useSyncedValue = ({
  name,
  documentRef,
  initialValue,
}: {
  name: string
  documentRef: firestore.DocumentReference
  initialValue: any
}) => {
  const [value, setValue] = useState(initialValue)
  const [debounced] = useDebounce(value, 500)
  const [status, setStatus] = useState(EditorSyncStatus.Live)

  useEffect(() => {
    if (value !== debounced) {
      setStatus(EditorSyncStatus.Modified)
    }
  }, [value, debounced])

  const update = async () => {
    setStatus(EditorSyncStatus.Syncing)
    await documentRef.set({ [name]: debounced }, { merge: true })
    setStatus(EditorSyncStatus.Live)
  }

  useEffect(() => {
    if (debounced !== initialValue) {
      update()
    }
    // eslint-disable-next-line
  }, [debounced])

  return {
    status,
    value,
    setValue,
  }
}
