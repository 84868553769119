import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import firebase, { firestore } from 'firebase/app'
import {
  useAuthedDocumentRef,
  useAuthedDocument,
} from '@humancollective/build-firebase'
import isEmpty from 'lodash/isEmpty'
import { Breadcrumbs, Loading } from '@humancollective/build-ui'
import { EditorFormFields, EditorFormField } from '@humancollective/build-forms'
import { useHistory } from 'react-router-dom'

import DraftBanner from './DraftBanner'
import StyledEditorTemplate from './style'

interface EditorTemplateProps {
  formSchema: EditorFormField[]
  validationSchema?: any
  className?: string
  defaultValues?: any
  previewUrl?: string
  breadcrumbs?: { label: string; to?: string }[]
  getQueryRef: (
    firebaseUserId: string,
    dependencies?: any[] | undefined
  ) => firestore.DocumentReference
}

const EditorTemplate: FunctionComponent<EditorTemplateProps> = ({
  formSchema,
  defaultValues = {},
  previewUrl,
  getQueryRef,
  breadcrumbs,
}) => {
  const [loading, setLoading] = useState(false)
  const values = useAuthedDocument(getQueryRef)
  const documentRef = useAuthedDocumentRef(getQueryRef)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const history = useHistory()

  const draftRef = useAuthedDocumentRef((...args) => {
    const docRef = getQueryRef(...args)
    return firebase
      .app('dashboard')
      .firestore()
      .collection('drafts')
      .doc([docRef.firestore.app.name, docRef.path].join('/'))
  })

  const draft = useAuthedDocument((...args) => {
    const docRef = getQueryRef(...args)
    return firebase
      .app('dashboard')
      .firestore()
      .collection('drafts')
      .doc([docRef.firestore.app.name, docRef.path].join('/'))
  })

  const publishDraft = async () => {
    if (documentRef && draftRef) {
      setLoading(true)
      await (documentRef as firebase.firestore.DocumentReference).set(draft, {
        merge: true,
      })
      await (draftRef as firebase.firestore.DocumentReference).delete()
      setLoading(false)
    }
  }

  const clearDraft = async () => {
    if (draftRef) {
      setLoading(true)
      await (draftRef as firebase.firestore.DocumentReference).delete()
      history.go(0)
      setLoading(false)
    }
  }

  const updatePreview = useCallback(() => {
    const message = JSON.stringify({
      type: 'preview-data',
      value: { ...(values || {}), ...(draft || {}) },
    })
    iframeRef?.current?.contentWindow?.postMessage(message, '*')
  }, [values, draft])

  useEffect(() => {
    const handler = (event: any) => {
      if (event.data === 'update-preview') {
        updatePreview()
      }
    }

    window.addEventListener('message', handler)
    return () => window.removeEventListener('message', handler)
  }, [updatePreview])

  useEffect(updatePreview, [iframeRef, updatePreview])

  return values && documentRef && draft !== undefined ? (
    <StyledEditorTemplate>
      {loading && <Loading />}
      <div className="editor-template__form">
        <div className="editor-template__contents-container">
          <div className="editor-template__columns">
            <div className="editor-template__fields">
              {breadcrumbs && <Breadcrumbs items={breadcrumbs} />}
              <br />
              <EditorFormFields
                values={{ ...defaultValues, ...values, ...draft }}
                documentRef={documentRef as firestore.DocumentReference}
                formSchema={formSchema}
              />
            </div>
            <div className="editor-template__preview-container">
              {previewUrl && (
                <iframe
                  className="editor-template__preview"
                  src={previewUrl}
                  title="preview"
                  onLoad={updatePreview}
                  ref={iframeRef}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(draft) && (
        <DraftBanner publishDraft={publishDraft} clearDraft={clearDraft} />
      )}
    </StyledEditorTemplate>
  ) : null
}

export default EditorTemplate
