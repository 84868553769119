import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
} from 'react'
import firebase, { firestore } from 'firebase'

import { useStateDictionary } from '../../hooks/useStateDictionary'
import { UsersContext } from '../../contexts/Users'

type Value = firebase.User | null | undefined
type Listener = () => void

interface QueriesContextValue {
  add: (query: Query) => void
  off: (id: string) => void
  clear: () => void
  values: { [appId: string]: Value }
}

export const QueriesContext = createContext<QueriesContextValue>({
  add: () => {},
  off: () => {},
  clear: () => {},
  values: {},
})

type CollectionQueryRef = firestore.CollectionReference | firestore.Query
type DocumentQueryRef = firestore.DocumentReference

type CollectionQuery = {
  id: string
  type: 'collection'
  ref: CollectionQueryRef
  limit?: number
}
type CountQuery = { id: string; type: 'count'; ref: CollectionQueryRef }
type DocumentQuery = { id: string; type: 'document'; ref: DocumentQueryRef }

export type Query = CollectionQuery | CountQuery | DocumentQuery

export const QueriesContextProvider: FunctionComponent = ({ children }) => {
  const listeners = useStateDictionary<Listener>()
  const values = useStateDictionary()
  const queue = useStateDictionary<Query>()

  const users = useContext(UsersContext)

  // When the auth status changes for an account, we check
  // the queue to see if we can register any queued listeners.
  useEffect(() => {
    Object.keys(queue.values).forEach(id => {
      const query = queue.get(id)
      if (!query) {
        return
      }
      const { app } = query.ref.firestore
      // If the user is active, we can register the listener
      // and remove it from the queue.
      if (users.get(app.name)) {
        add(query)
        queue.remove(id)
      }
    })
  }, [users.values])

  const addCollectionListener = (query: CollectionQuery) =>
    listeners.set(
      query.id,
      query.ref.onSnapshot(value => {
        values.set(
          query.id,
          value.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          })),
        )
      }),
    )

  const addCountListener = (query: CountQuery) =>
    listeners.set(
      query.id,
      query.ref.onSnapshot(value => {
        values.set(query.id, value.size)
      }),
    )

  const addDocumentListener = (query: DocumentQuery) =>
    listeners.set(
      query.id,
      query.ref.onSnapshot(doc =>
        values.set(query.id, {
          id: doc.id,
          ...doc.data(),
        }),
      ),
    )

  const add = (query: Query) => {
    // Ensure that the user is logged into this app.
    // Otherwise, queue the query for later.
    const app = query.ref.firestore.app
    if (!users.get(app.name)) {
      users.monitor(app)
      queue.set(query.id, query)
      return
    }

    // Add the listener.
    switch (query.type) {
      case 'collection':
        addCollectionListener(query)
        break
      case 'count':
        addCountListener(query)
        break
      case 'document':
        addDocumentListener(query)
        break
    }
  }

  const off = (id: string) => {
    listeners.remove(id)
    values.remove(id)
  }

  const clear = () => {
    Object.values(listeners.values).map(off => off())
    listeners.clear()
    values.clear()
  }

  return (
    <QueriesContext.Provider
      value={{
        add,
        off,
        clear,
        values: values.values,
      }}
    >
      {children}
    </QueriesContext.Provider>
  )
}
