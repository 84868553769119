import styled from 'styled-components'
import { palette } from '@humancollective/build-ui'

const size = 22

export const StyledSwitchFieldFormGroup = styled.div<{ focused: boolean }>`
  .switchField-form-group {
    &__label {
      ${({ focused }) => (focused ? `color: ${palette.primary};` : '')}
    }
    &__input {
      display: block;
      appearance: none;
      width: ${size * 2 - 4}px;
      height: ${size}px;
      border-radius: 28px;
      background-color: #f8f9fb;
      border: 1px solid #ced4db;
      position: relative;
      cursor: pointer;
      transition: background-color 500ms ease-in-out,
        border-color 500ms ease-in-out;
      &:after {
        content: '';
        display: block;
        border: 1px solid #ced4db;
        height: ${size - 8}px;
        width: ${size - 8}px;
        background-color: white;
        border-radius: 50%;
        left: 2px;
        top: 2px;
        position: absolute;
        transition: left 200ms ease-in-out, border-color 500ms ease-in-out;
      }
      &:hover {
        background-color: #e6e8ec;
      }
      &:checked {
        background-color: ${palette.primary};
        border-color: ${palette.primary};
        &:hover {
          background-color: ${palette.primaryHover};
        }
        &:after {
          left: ${size - 2}px;
          border-color: white;
        }
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px #cae2ff;
      }
    }
  }
`
