import styled from 'styled-components'
import {
  BASE_RADIUS,
  rhythm,
  scaleFontSize,
  Card,
} from '@humancollective/build-ui'

export const StyledDashboardCard = styled(Card)`
  display: flex;
  flex-direction: column;

  .dashboard-card {
    &__top {
      display: flex;
      align-items: flex-start;
    }
    &__middle {
      flex: 1;
    }
    &__bottom {
      display: flex;
      align-items: flex-end;
    }

    &__name {
      ${scaleFontSize(2)};
      margin-bottom: ${rhythm(1 / 2)}px;
      font-weight: 900;
      flex: 1;
    }
    &__icon {
      width: ${rhythm(1 / 2)}px;
      margin-right: ${rhythm(1 / 3)}px;
    }
    &__type {
      ${scaleFontSize(-2)};
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      span {
        background-color: #f8f9fb;
        color: #636c82;
        display: inline-block;
        padding-left: ${rhythm(1 / 3)}px;
        padding-right: ${rhythm(1 / 3)}px;
        border-radius: ${BASE_RADIUS}px;
      }
    }
  }
`
