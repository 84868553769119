import React, { FunctionComponent } from 'react'
import firebase from 'firebase/app'

import { EditorFormField } from '../types'
import { getSyncedDraftFormField } from '../utils/getFormField'

export interface EditorFormFieldsProps {
  values: any
  formSchema: EditorFormField[]
  documentRef: firebase.firestore.DocumentReference
}

export const EditorFormFields: FunctionComponent<EditorFormFieldsProps> = ({
  values,
  formSchema,
  documentRef,
}) => (
  <>
    {formSchema.map((element: EditorFormField, index: number) =>
      getSyncedDraftFormField(
        { ...(element as EditorFormField), values, documentRef },
        index
      )
    )}
  </>
)
