import React, { FunctionComponent, ReactNode } from 'react'

import { StyledDashboardCard } from './style'

interface DashboardCardProps {
  className?: string
  label: string
  icon: string
  type: string
  middleContents?: ReactNode
  bottomContents?: ReactNode
}

export const DashboardCard: FunctionComponent<DashboardCardProps> = ({
  className,
  label,
  icon,
  type,
  middleContents,
  bottomContents,
}) => (
  <StyledDashboardCard className={className}>
    <div className="dashboard-card__top">
      <div className="dashboard-card__name">{label}</div>
      <div className="dashboard-card__type">
        <span>
          <img className="dashboard-card__icon" src={icon} alt={type} />
          {type}
        </span>
      </div>
    </div>
    <div className="dashboard-card__middle">{middleContents}</div>
    <div className="dashboard-card__bottom">{bottomContents}</div>
  </StyledDashboardCard>
)
