import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import sanitizeHtml from 'sanitize-html'

import { HtmlFieldSelectionMenu } from './components/SelectionMenu'
import { DEFAULT_ALLOWED_ATTRIBUTES, DEFAULT_ALLOWED_TAGS } from './config'
import { StyledHtmlField } from './style'
import { EntryMode, EntryModeType } from './types'

export interface HtmlFieldProps {
  className?: string
  value: string
  onChangeValue: (value: string) => void
  onPaste?: (e: React.ClipboardEvent<HTMLDivElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  allowedTags?: string[]
  allowedAttributes?: { [tag: string]: string[] }
  tagName?: string
  preview?: boolean
  onFocus?: () => void
  onBlur?: () => void
}

// This is used to clean up some browser quirks for the handling of whitespace
// characters. By normalizing these values, we can do ensure consistent values
// are saved, and safely do things like string comparrison to see if HTML
// content was pasted or if the user is simply typing.
const normalizeWhitespace = (value: string) =>
  value.replace(/[\f\n\r\t\v\s ]|&nbsp;/g, ' ').replace(/<br>/g, '<br />')

export const HtmlField: FunctionComponent<HtmlFieldProps> = ({
  className,
  value,
  onChangeValue,
  onFocus,
  onBlur,
  onClick,
  onKeyDown,
  onPaste,
  allowedTags = DEFAULT_ALLOWED_TAGS,
  allowedAttributes = DEFAULT_ALLOWED_ATTRIBUTES,
  preview = false,
}) => {
  const [focused, setFocused] = useState(false)
  const [entryMode, setEntryMode] = useState<EntryMode>()
  const editorRef = useRef<HTMLDivElement>(null)

  const sanitizeValue = (dirtyValue: string) =>
    normalizeWhitespace(
      sanitizeHtml(dirtyValue, {
        allowedTags,
        allowedAttributes,
      })
    )

  const initialValue = useMemo(() => sanitizeValue(value), [])

  // accept changes from the controlled "value" prop
  // (e.g., for realtime updates from other users during collaborative editing)
  useEffect(() => {
    const valueHasChanged = value !== editorRef?.current?.innerHTML
    if (!focused && valueHasChanged && editorRef?.current) {
      editorRef.current.innerHTML = value
    }
  }, [value])

  const updateEntryMode = () => {
    const selection = window.getSelection()
    if (selection?.type === 'Range') {
      setEntryMode({
        type: EntryModeType.Range,
        range: selection.getRangeAt(0),
      })
    } else {
      setEntryMode({ type: EntryModeType.Insert })
    }
  }

  const handleFocus = useCallback(() => {
    setFocused(true)
    onFocus && onFocus()
  }, [onFocus])

  const handleBlur = useCallback(() => {
    setFocused(false)
    onBlur && onBlur()
  }, [onBlur])

  const handleInput = useCallback(() => {
    const nextValue = sanitizeValue(editorRef?.current?.innerHTML || '')
    if (
      editorRef?.current?.innerHTML &&
      normalizeWhitespace(editorRef.current.innerHTML) != nextValue
    ) {
      editorRef.current.innerHTML = nextValue
    }
    onChangeValue(nextValue)
  }, [onChangeValue])

  return (
    <>
      <StyledHtmlField
        className={`build-field-html__editable ${className || ''}`}
        ref={editorRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        onKeyUp={updateEntryMode}
        onPaste={onPaste}
        onInput={handleInput}
        onClick={onClick}
        onMouseUp={updateEntryMode}
        dangerouslySetInnerHTML={{ __html: initialValue }}
        contentEditable={!preview}
      />
      {entryMode?.type === EntryModeType.Range && (
        <HtmlFieldSelectionMenu
          onClose={() => setEntryMode({ type: EntryModeType.Insert })}
          triggerOnChange={handleInput}
          editorRef={editorRef}
          range={entryMode.range}
        />
      )}
    </>
  )
}
