import React, { FunctionComponent, useEffect, useState } from 'react'
import { app } from 'firebase/app'
import styled from 'styled-components'
import { useAuthedDocument } from '@humancollective/build-firebase'
import { useParams } from 'react-router-dom'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import { rhythm, PopoverMenu } from '@humancollective/build-ui'

import { AppCard } from '../../components/cards/AppCard'
import { IconEllipsis } from '../../icons/Ellipsis'

export interface ViewDashboardRouteParams {
  dashboardId: string
}

const StyledProjectPage = styled.div<{ editing: boolean }>`
  background: #f8f9fb;
  flex: 1;

  .project-page {
    &__info {
      display: flex;
      align-items: center;
      padding: 24px 40px 0;

      h1 {
        font-weight: 400;
      }

      &__spacer {
        flex: 1;
      }
    }
    &__more-button {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      border: none;
      background-color: white;
      box-shadow: 0 24px 32px #e3e6ec61;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      transition: box-shadow 400ms ease-in-out, background-color 250ms ease,
        color 250ms ease;

      &:hover {
        box-shadow: 0 32px 40px #e3e6ec61;
      }

      &.is--active {
        background-color: #072bff;
        color: white;
      }
    }
    &__apps {
      padding-top: ${rhythm()}px;
      padding-bottom: ${rhythm()}px;
      padding-left: ${rhythm()}px;
      padding-right: ${rhythm()}px;

      ${({ editing }) =>
        editing
          ? `
            max-width: ${rhythm(20)}px;
            margin-left: auto;
            margin-right: auto;
          `
          : `
            display: flex;
            flex-wrap: wrap;
            > div {
              display: flex;
              flex: 1;
            }
      `}
    }
    &__controls {
      display: flex;
    }
  }
`

const getApp = async (appId: string) => {
  const appDoc = await app('dashboard')
    .firestore()
    .collection('apps')
    .doc(appId)
    .get()
  return { ...appDoc.data(), id: appDoc.id }
}

const updateApps = async (apps: any) => {
  const requests: Promise<any>[] = []
  for (const appId of apps) {
    requests.push(getApp(appId))
  }
  const nextApps = await Promise.all(requests)
  return nextApps
}

const removeAppFromDashboard = async (appId: string, dashboardId: string) => {
  const dashboardDoc = await app('dashboard')
    .firestore()
    .collection('dashboards')
    .doc(dashboardId)
    .get()
  const dashboard = { ...dashboardDoc.data(), id: dashboardDoc.id } as any
  await dashboardDoc.ref.update({
    apps: dashboard?.apps?.filter((id: string) => id !== appId) || [],
  })
}

const ProjectPage: FunctionComponent = () => {
  const { dashboardId } = useParams<ViewDashboardRouteParams>()
  const [apps, setApps] = useState([] as any[])
  const [editing, setEditing] = useState(false)
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)

  const project = useAuthedDocument(
    (_uid, deps) =>
      app('dashboard')
        .firestore()
        .collection('dashboards')
        .doc(deps && deps[0] ? deps[0] : 'loading'),
    [dashboardId]
  )

  // const permissions = useAuthedDocument(
  //   (uid, deps) =>
  //     app('dashboard')
  //       .firestore()
  //       .collection('permissions')
  //       .doc(uid)
  //       .collection('dashboards')
  //       .doc(deps && deps[0] ? deps[0] : 'loading'),
  //   [dashboardId]
  // )

  useEffect(() => {
    if (project?.apps) {
      updateApps(project.apps).then(setApps)
    }
  }, [project])

  const onDragEnd = async (e: DropResult) => {
    if (!project) {
      throw new Error('called onDragEnd before project was loaded')
    }
    if (typeof e.destination?.index === 'number') {
      const apps = [...project.apps]
      const [value] = apps.splice(e.source.index, 1)
      apps.splice(e.destination.index, 0, value)
      await app('dashboard')
        .firestore()
        .collection('dashboards')
        .doc(dashboardId)
        .update({ apps })
    }
  }

  return project && apps ? (
    <StyledProjectPage editing={editing}>
      <div className="project-page__info">
        <h1>{project?.name}</h1>
        <div className="project-page__info__spacer" />
        <PopoverMenu
          isOpen={isOptionsMenuOpen}
          positions={['bottom', 'left']}
          onClickOutside={() => setIsOptionsMenuOpen(false)}
          items={[
            {
              type: 'label',
              text: 'options',
            },
            {
              type: 'option',
              text: editing ? 'Stop Editing Apps' : 'Edit Apps',
              onClick: () => setEditing(!editing),
            },
            // {
            //   type: 'option',
            //   text: 'Add App',
            // },
            // {
            //   type: 'option',
            //   text: 'Remove Dashboard',
            //   appearance: 'danger',
            // },
          ]}
        >
          <button
            className={`project-page__more-button ${
              isOptionsMenuOpen ? 'is--active' : ''
            }`}
            onClick={() => setIsOptionsMenuOpen((isOpen) => !isOpen)}
          >
            <IconEllipsis />
          </button>
        </PopoverMenu>
      </div>

      {/* <AddApp
        onAddApp={(id: string) =>
          app('dashboard')
            .firestore()
            .collection('dashboards')
            .doc(dashboardId)
            .update({ apps: [...project.apps, id] })
        }
      /> */}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <div
              className="project-page__apps"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {apps.map((app, index) =>
                editing ? (
                  <Draggable key={app.id} draggableId={app.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                      >
                        <AppCard
                          key={app.id}
                          app={app}
                          dashboardId={dashboardId}
                          onRemove={
                            editing
                              ? () =>
                                  removeAppFromDashboard(app.id, dashboardId)
                              : undefined
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ) : (
                  <AppCard
                    key={app.id}
                    app={app}
                    dashboardId={dashboardId}
                    onRemove={
                      editing
                        ? () => removeAppFromDashboard(app.id, dashboardId)
                        : undefined
                    }
                  />
                )
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </StyledProjectPage>
  ) : null
}

export default ProjectPage
