import { useContext, useEffect } from 'react'
import { QueriesContext, Query } from '../contexts/Queries'

export const usePersistentQuery = (query: Query) => {
  const queries = useContext(QueriesContext)
  useEffect(() => {
    queries.add(query)
  }, [])
  return queries.values[query.id]
}
