import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  useAuthedDocument,
  buildDocumentRef,
} from '@humancollective/build-firebase'
import {
  Card,
  rhythm,
  scaleFontSize,
  BASE_RADIUS,
  useLoading,
} from '@humancollective/build-ui'

import { EditorFormField, EditorFormFieldProps } from '../../types'
import { getNestableFieldComponentByType } from '../../utils/nestableFieldComponentByType'
import { SyncedFormField } from '../../components/SyncedFormField'

export const getSyncedFormField = (
  { fieldType, name, ...rest }: EditorFormField & EditorFormFieldProps,
  index: number
) => (
  <SyncedFormField
    key={name || `editor-form-field--${index}`}
    name={name}
    Component={getNestableFieldComponentByType(fieldType)}
    {...rest}
  />
)

const StyledExternalField = styled(Card)`
  margin-left: 0;
  margin-right: 0;

  .external-field {
    &__link {
      display: flex;
      align-items: center;
      padding: ${rhythm(1 / 2)}px;
      &__image {
        margin-right: ${rhythm()}px;
        border-radius: ${BASE_RADIUS}px;
      }
      &__title {
        font-weight: 900;
      }
      &__subtitle {
        ${scaleFontSize(-1)};
        color: #757575;
      }
    }
    &__add-connection {
      display: flex;
      margin-top: ${rhythm()}px;
      &__select {
        flex: 1;
        margin-right: ${rhythm(1 / 2)}px;
      }
    }
  }
`

export interface ExternalFieldProps {
  className?: string
  fields: EditorFormField[]
  collectionPath: string
  label: string
}

export const ExternalField: FunctionComponent<ExternalFieldProps &
  EditorFormFieldProps> = ({
  label,
  fields,
  collectionPath,
  documentRef,
  className,
}) => {
  const app = documentRef.firestore.app
  const id = documentRef.id
  const externalRef = buildDocumentRef(`${collectionPath}.${id}`, app)

  const values = useAuthedDocument(externalRef)
  useLoading(!!values)

  return values ? (
    <StyledExternalField className={className}>
      <h4>{label}</h4>
      <br />
      {fields.map((element: EditorFormField, index: number) =>
        getSyncedFormField(
          { ...(element as EditorFormField), values, documentRef: externalRef },
          index
        )
      )}
    </StyledExternalField>
  ) : null
}
