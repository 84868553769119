import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import EditPage, { EditDocumentRouteParams } from './Edit'
import ListPage, { ListCollectionRouteParams } from './List'

export const editDocumentRoute = ({
  dashboardId,
  collectionId,
  docId,
}: EditDocumentRouteParams) =>
  dashboardId
    ? `/d/${dashboardId}/c/${collectionId}/d/${docId}`
    : `/c/${collectionId}/d/${docId}`

export const listCollectionRoute = ({
  dashboardId,
  collectionId,
}: ListCollectionRouteParams) =>
  dashboardId ? `/d/${dashboardId}/c/${collectionId}/` : `/c/${collectionId}/`

const CollectionRouter: FunctionComponent = () => (
  <Switch>
    <Route
      exact
      path={[
        editDocumentRoute({
          collectionId: ':collectionId',
          docId: ':docId',
        }),
        editDocumentRoute({
          dashboardId: ':dashboardId',
          collectionId: ':collectionId',
          docId: ':docId',
        }),
      ]}
    >
      <EditPage />
    </Route>
    <Route
      exact
      path={[
        listCollectionRoute({
          collectionId: ':collectionId',
        }),
        listCollectionRoute({
          dashboardId: ':dashboardId',
          collectionId: ':collectionId',
        }),
      ]}
    >
      <ListPage />
    </Route>
  </Switch>
)

export default CollectionRouter
