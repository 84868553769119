import React, { FunctionComponent, useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  rhythm,
  Button,
  DisplayTemplate,
  getDisplayValues,
} from '@humancollective/build-ui'

import folderIcon from '../../../assets/jollycons/folder.svg'
import { AuthContext } from '../../../contexts/AuthContext'
import { DisplayIcon } from '../../display/Icon'
import { StyledCollectionCard } from './style'
import { buildCollectionRef } from './utils'
import { LoadingCard } from '../LoadingCard'
import { usePersistentQuery } from '@humancollective/build-firebase'

interface CollectionCardProps {
  collection: {
    id: string
    label: string
    firebasePath: string
    firebaseConfig: any
    display: DisplayTemplate
  }
  className?: string
  onClick?: () => void
}

export const CollectionCard: FunctionComponent<CollectionCardProps> = ({
  collection,
  className,
  onClick,
}) => {
  const { getApp, isAdmin } = useContext(AuthContext)

  const app = getApp(collection)
  const queryRef = buildCollectionRef(collection.firebasePath, app)

  const count = usePersistentQuery({
    id: `${collection.id}.count`,
    ref: queryRef,
    type: 'count',
  })
  const examples = usePersistentQuery({
    id: `${collection.id}.preview`,
    ref: queryRef.limit(3),
    type: 'collection',
  })

  const hasIcon = !!collection.display?.icon
  const isLoading = typeof count !== 'number' || examples === undefined

  return isLoading ? (
    <LoadingCard
      className={className}
      label={collection.label}
      icon={folderIcon}
      type="Collection"
    />
  ) : (
    <StyledCollectionCard
      className={className}
      label={collection.label}
      icon={folderIcon}
      type="Collection"
      bottomContents={
        <>
          <div className="collection-card__examples">
            {hasIcon &&
              examples?.map(item => {
                const display = getDisplayValues(item, collection?.display)
                return (
                  <DisplayIcon
                    key={item.id}
                    className="collection-card__examples__icon"
                    size={rhythm(1.5)}
                    round
                    {...display.icon}
                  />
                )
              })}
            {typeof count === 'number'
              ? `${count.toLocaleString()} items`
              : null}
          </div>
          {isAdmin && (
            <Button
              size="small"
              appearance="ghost"
              tagName={Link}
              to={`/a/${collection.id}/edit/`}
              color="#636c82"
            >
              Edit
            </Button>
          )}
          <Button onClick={onClick} size="small" appearance="ghost">
            View &rarr;
          </Button>
        </>
      }
    />
  )
}
