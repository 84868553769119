import {
  FontStack,
  BASE_FONT_SIZE,
  FONT_SCALE_INTERVAL,
  BASELINE_HEIGHT,
  HEADING_FONT_WEIGHT,
  Palette,
} from '../theme'

const fontInterval = (steps = 0) => {
  const [ratio, stepSize] = FONT_SCALE_INTERVAL
  return BASE_FONT_SIZE * Math.pow(ratio, steps / stepSize)
}

const snapBaseline = (value: number, contain = true) => {
  const ratio = value / BASELINE_HEIGHT
  const snappedUnits = contain ? Math.ceil(ratio) : Math.round(ratio)
  return snappedUnits * BASELINE_HEIGHT
}

export const rhythm = (value = 1) => value * BASELINE_HEIGHT

export const scaleFontSize = (degree: number) => `
  font-size: ${fontInterval(degree)}px;
  line-height: ${snapBaseline(fontInterval(degree))}px;
`

const headingStyle = `
  margin: 0;
  color: ${Palette.OffBlack};
  font-family: ${FontStack.SansSerif};
  font-weight: ${HEADING_FONT_WEIGHT};
  text-rendering: optimizeLegibility;
`

export const typography = {
  h1: `
    ${headingStyle};
    ${scaleFontSize(5)};
  `,
  h2: `
    ${headingStyle};
    ${scaleFontSize(4)};
  `,
  h3: `
    ${headingStyle};
    ${scaleFontSize(3)};
  `,
  h4: `
    ${headingStyle};
    ${scaleFontSize(2)};
  `,
  h5: `
    ${headingStyle};
    ${scaleFontSize(1)};
  `,
  h6: `
    ${headingStyle};
    ${scaleFontSize(0)};
    text-transform: uppercase;
  `,
  p: `
    ${scaleFontSize(0)};
  `,
  blockquote: `
    ${scaleFontSize(0)};
    font-style: italic;
    padding-left: ${rhythm(1)}px;
  `,
  pre: `
    ${scaleFontSize(0)};
    font-family: ${FontStack.Monospace};
  `,
  code: `
    font-size: inherit;
    line-height: inherit;
    font-family: ${FontStack.Monospace};
    background: ${Palette.OffWhite};
    color: ${Palette.OffBlack};
    padding-left: ${rhythm(1 / 3)}px;
    padding-right: ${rhythm(1 / 3)}px;
    padding-top: ${rhythm(1 / 6)}px;
    padding-bottom: ${rhythm(1 / 6)}px;
  `,
  big: `
    ${scaleFontSize(1)};
  `,
  small: `
    ${scaleFontSize(-1)};
  `,
  formElement: `
    font-family: ${FontStack.UI};
    ${scaleFontSize(0)};
    margin: 0;
  `,
  list: `
    padding-left: ${rhythm(2)}px;
  `,
}
