export const DEFAULT_ALLOWED_TAGS = [
  'b',
  'i',
  'a',
  'strong',
  'em',
  'strike',
  'span',
  'p',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'br',
  'div',
]

export const DEFAULT_ALLOWED_ATTRIBUTES = { a: ['href'] }
