import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce/lib'
import { rhythm, Card } from '@humancollective/build-ui'

const StyledUrlPreview = styled(Card)`
  margin-top: ${rhythm()}px;
  padding: 0;
  background-color: #000000;
  max-width: 360px;

  .url-preview {
    &__container {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
    }
    &__iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
`

export interface UrlPreviewProps {
  title: string
  value: string
  className?: string
}

export const UrlPreview: FunctionComponent<UrlPreviewProps> = ({
  title,
  value,
  className,
}) => {
  const [debounced] = useDebounce(value, 500)

  const shouldPreview =
    debounced &&
    (debounced.includes('https://player.vimeo.com/') ||
      debounced.includes('https://player.castr.io/'))

  return shouldPreview ? (
    <StyledUrlPreview className={className}>
      <div className="url-preview__container">
        <iframe
          title={title}
          className="url-preview__iframe"
          src={debounced}
          frameBorder="none"
        />
      </div>
    </StyledUrlPreview>
  ) : null
}
