import React, { FunctionComponent, useMemo } from 'react'
import {
  useAuthedCollection,
  buildCollectionRef,
} from '@humancollective/build-firebase'
import { firestore } from 'firebase/app'
import get from 'lodash/get'

import { SelectField, SelectFieldProps } from '../SelectField'

export interface ReferenceFieldProps extends SelectFieldProps {
  documentRef: firestore.DocumentReference
  firebasePath: string
  labelKey: string
}

export const ReferenceField: FunctionComponent<ReferenceFieldProps> = ({
  firebasePath,
  documentRef,
  labelKey = 'name',
  options,
  ...rest
}) => {
  const refrenceOptions = useAuthedCollection(() =>
    buildCollectionRef(firebasePath, undefined, documentRef.firestore.app)
  )

  const transformedReferenceOptions = useMemo(
    () =>
      (refrenceOptions || [])?.map(v => ({
        label: get(v, labelKey),
        value: v.id,
      })),
    [refrenceOptions]
  )

  return refrenceOptions ? (
    <SelectField options={transformedReferenceOptions} {...rest} />
  ) : null
}
