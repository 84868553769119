import React from 'react'
import Editor from 'react-simple-code-editor'
import { FontStack, BASE_FONT_SIZE, rhythm } from '@humancollective/build-ui'
import * as Prism from 'prismjs'
import 'prismjs/components/prism-json'

interface ObjectEditorProps {
  value: string
  onChange: (value: string) => void
}

export const ObjectEditor: React.FunctionComponent<ObjectEditorProps> = ({
  value,
  onChange,
}) => (
  <Editor
    value={value}
    onValueChange={onChange}
    highlight={(code) => Prism.highlight(code, Prism.languages.json, 'json')}
    padding={rhythm(1 / 2)}
    style={{
      fontFamily: FontStack.Monospace,
      fontSize: BASE_FONT_SIZE,
    }}
  />
)
