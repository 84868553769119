import React from 'react'
import { Helmet } from 'react-helmet'

export const HelmetFonts = () => (
  <Helmet key="helmet-fonts">
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
  </Helmet>
)
