import { FunctionComponent } from 'react'

import { EditorFormFieldType, EditorFormFieldProps } from '../types'
import { SwitchField } from '../fields/SwitchField'
import { SelectField } from '../fields/SelectField'
import { DateTimeField } from '../fields/DateTimeField'
import { ImageField } from '../fields/ImageField'
import { InputField } from '../fields/InputField'
import { RelationshipField } from '../fields/RelationshipField'

export const nestableFieldComponentByType: {
  [type: string]: FunctionComponent<any & EditorFormFieldProps>
} = {
  [EditorFormFieldType.Text]: InputField,
  [EditorFormFieldType.Select]: SelectField,
  [EditorFormFieldType.Switch]: SwitchField,
  [EditorFormFieldType.Date]: DateTimeField,
  [EditorFormFieldType.Image]: ImageField,
  [EditorFormFieldType.Relationship]: RelationshipField,
}

export const getNestableFieldComponentByType = (
  fieldType: EditorFormFieldType
) =>
  nestableFieldComponentByType[fieldType] ||
  nestableFieldComponentByType[EditorFormFieldType.Text]
