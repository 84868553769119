import React from 'react'
import styled from 'styled-components'

import { Card } from '../Card'

const StyledEmptyState = styled(Card)`
  background-color: #f8f9fb;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
`

interface EmptyStateProps {
  label: string
}

export const EmptyState: React.FunctionComponent<EmptyStateProps> = ({
  label,
}) => (
  <StyledEmptyState>
    <p>{label}</p>
  </StyledEmptyState>
)
