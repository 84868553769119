import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '@humancollective/build-firebase'
import firebase from 'firebase/app'
import styled from 'styled-components'
import { gridColumn } from '@humancollective/build-grid'
import { Card, Button, rhythm, LoadSpinner } from '@humancollective/build-ui'
import { InputField } from '@humancollective/build-forms'

import { RecaptchaContext } from '../../../contexts/RecaptchaContext'

const StyledContactForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .contact-form {
    &__grid {
      display: flex;
      flex: 1;
    }
    &__contents {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      h2 {
        margin-bottom: ${rhythm()}px;
      }
    }
    &__form-container {
      max-width: 450px;
    }
    &__input {
      margin-bottom: 18px;
    }
    &__submit {
      margin-top: 18px;
    }
    &__privacy {
      display: block;
      max-width: ${gridColumn(4)};
      margin-top: ${rhythm()}px;
      color: #949494;
      a {
        color: #949494;
      }
    }
    &__loading {
      display: flex;
      flex-direction: column;
      align-items: center;
      small {
        margin-top: ${rhythm()}px;
        color: #9c9c9c;
      }
    }
    &__success {
      max-width: ${gridColumn(5)};
    }
  }
`

export const ContactForm = () => {
  const history = useHistory()
  const { recaptchaToken } = useContext(RecaptchaContext)
  const [message, setMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const disabled = !message

  const { firebaseUser } = useAuth(firebase.app('dashboard'))

  const onSubmit = async () => {
    setErrorMessage('')
    setLoading(true)
    setSuccess(false)

    const response = await fetch('https://hmn.to/relay/vvNAXS8SwvWhL95mYsZt', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: firebaseUser?.uid,
        message,
        recaptchaToken,
      }),
    })
    const data = await response.json()

    setLoading(false)
    if (data.status === 'error') {
      setErrorMessage(data.message)
    } else {
      setSuccess(true)
    }
  }

  return (
    <StyledContactForm>
      {success && (
        <Card className="contact-form__success">
          <h3>Success!</h3>
          <p>
            We've recieved your request and you can expect to hear back from us
            shortly.
          </p>
          <br />
          <Button onClick={() => history.goBack()} appearance="outline">
            Done
          </Button>
        </Card>
      )}
      {!success &&
        (!loading ? (
          <form className="contact-form__form-container" onSubmit={onSubmit}>
            <InputField
              value={message}
              onChangeValue={setMessage}
              name="message"
              className="contact-form__input"
              label="Your Request"
              placeholder="What would you like to request?"
              multiline
            />
            <Button
              className="contact-form__submit"
              type="submit"
              onClick={onSubmit}
              disabled={disabled}
            >
              Submit
            </Button>{' '}
            <Button
              className="contact-form__cancel"
              type="button"
              onClick={() => history.goBack()}
              appearance="outline"
            >
              Cancel
            </Button>
            {errorMessage && (
              <small>
                <strong>Error: {errorMessage}</strong>
              </small>
            )}
            <small className="contact-form__privacy">
              This site is protected by reCAPTCHA and the Google{' '}
              <a href="https://policies.google.com/privacy">Privacy Policy</a>{' '}
              and{' '}
              <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
              apply.
            </small>
          </form>
        ) : (
          <div className="contact-form__loading">
            <LoadSpinner />
            <small>
              <strong>Sending Message&#8230;</strong>
            </small>
          </div>
        ))}
    </StyledContactForm>
  )
}
