import styled, { keyframes } from 'styled-components'

const MENU_WIDTH = 128
const MENU_HEIGHT = 36

const ShowContainerKeyframes = keyframes`
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const ShowButtonKeyframes = keyframes`
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const ShowInputKeyframes = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const StyledHtmlFieldSelectionMenu = styled.div`
  position: fixed;
  height: ${MENU_HEIGHT}px;
  width: ${MENU_WIDTH}px;
  background: #404040;
  margin-top: -${MENU_HEIGHT}px;
  margin-left: -${MENU_WIDTH / 2}px;
  display: flex;
  border-radius: 6px;
  animation: ${ShowContainerKeyframes} 250ms ease-in;
  overflow: hidden;

  input {
    flex-shrink: 1;
    display: block;
    width: 80px;
    background: #202020;
    border: none;
    padding: 8px;
    animation: ${ShowInputKeyframes} 250ms ease-in;
    outline: none;
    color: white;
  }

  > button {
    border-radius: 6px;
    background: #404040;
    color: white;
    flex: 1;
    height: 100%;
    cursor: pointer;
    border: none;
    opacity: 0;
    animation: ${ShowButtonKeyframes} 250ms ease-in forwards;
    animation-delay: 400ms;
    &:nth-of-type(1) {
      animation-delay: 0ms;
    }
    &:nth-of-type(2) {
      animation-delay: 100ms;
    }
    &:nth-of-type(3) {
      animation-delay: 200ms;
    }
    &:nth-of-type(4) {
      animation-delay: 300ms;
    }
  }
`
