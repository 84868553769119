import React, { FunctionComponent } from 'react'
import { join } from 'path'
import includes from 'lodash/includes'

interface BuildImgixUrlArgs {
  baseUrl?: string
  width?: number
  height?: number
  fit?: ImgixFitMode
  auto?: string[]
  autoCompress?: boolean
  autoFormat?: boolean
  pixelRatio?: number
  backgroundColor?: string
  sourceRectangleRegion?: string
  blendColor?: string
  blendMode?: ImgixBlendMode
  crop?: ImgixCropMode
  saturation?: number
}

export enum ImgixFitMode {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  FaceArea = 'facearea',
  Fill = 'fill',
  FillMax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale',
}

export enum ImgixCropMode {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Faces = 'faces',
  Focalpoint = 'focalpoint',
  Edges = 'edges',
  Entropy = 'entropy',
}

export enum ImgixBlendMode {
  Normal = 'normal',
  Darken = 'darken',
  Multiply = 'multiply',
  Burn = 'burn',
  Lighten = 'lighten',
  Screen = 'screen',
  Dodge = 'dodge',
  Overlay = 'overlay',
  SoftLight = 'softlight',
  HardLight = 'hardlight',
  Difference = 'difference',
  Exclusion = 'exclusion',
  Color = 'color',
  Hue = 'hue',
  Saturation = 'saturation',
  Luminosity = 'luminosity',
}

export const buildImgixUrl = (
  path: string = '',
  {
    baseUrl,
    width,
    height,
    fit,
    auto,
    autoCompress = true,
    autoFormat = true,
    pixelRatio,
    backgroundColor,
    sourceRectangleRegion,
    crop,
    blendColor,
    blendMode,
    saturation,
  }: BuildImgixUrlArgs = {},
) => {
  let url =
    path.indexOf('https://') === 0
      ? path
      : join('https://', baseUrl || process.env.REACT_APP_IMGIX_URL || '', path)

  const params = []

  if (width) {
    params.push(`w=${width}`)
  }
  if (height) {
    params.push(`h=${height}`)
  }
  if (fit) {
    params.push(`fit=${fit}`)
  }
  if (crop) {
    params.push(`crop=${crop}`)
  }
  if (pixelRatio) {
    params.push(`dpr=${pixelRatio}`)
  }
  if (backgroundColor) {
    params.push(`bg=${backgroundColor}`)
  }
  if (sourceRectangleRegion) {
    params.push(`rect=${sourceRectangleRegion}`)
  }

  if (blendColor) {
    params.push(`blend-color=${blendColor}`)
  }
  if (blendMode) {
    params.push(`blend-mode=${blendMode}`)
  }

  if (saturation) {
    params.push(`sat=${saturation}`)
  }

  // Automatic features (e.g., compression and format) let ImgIX choose the best
  // option based on the device and browser.

  const autoParams = auto || []
  if (autoCompress && !includes(autoParams, 'compress')) {
    autoParams.push('compress')
  }
  if (autoFormat && !includes(autoParams, 'format')) {
    autoParams.push('format')
  }
  params.push(`auto=${autoParams.join(',')}`)

  if (params.length) {
    url = [url, params.join('&')].join('?')
  }
  return url
}

export const buildImgixSrcSet = (path: string, args?: BuildImgixUrlArgs) => `
  ${buildImgixUrl(path, { ...args, pixelRatio: 1 })} 1x,
  ${buildImgixUrl(path, { ...args, pixelRatio: 2 })} 2x,
  ${buildImgixUrl(path, { ...args, pixelRatio: 3 })} 3x
`

interface ImgixProps {
  path: string
  options?: BuildImgixUrlArgs
}

export const Imgix: FunctionComponent<
  ImgixProps &
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
> = ({ path, options, ...rest }) => (
  <img
    src={buildImgixUrl(path, options)}
    srcSet={buildImgixSrcSet(path, options)}
    alt="imgix"
    {...rest}
  />
)
