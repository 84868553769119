import React, { FunctionComponent } from 'react'

import { EditorFormFieldType, EditorFormFieldProps } from '../types'
import { GroupField } from '../fields/GroupField'
import { ListField } from '../fields/ListField'
import { SwitchField } from '../fields/SwitchField'
import { SelectField } from '../fields/SelectField'
import { DateTimeField } from '../fields/DateTimeField'
import { ImageField } from '../fields/ImageField'
import { InputField } from '../fields/InputField'
import { ReferenceField } from '../fields/ReferenceField'
import { RelationshipField } from '../fields/RelationshipField'
import { ExternalField } from '../fields/ExternalField'
import { HtmlField } from '../fields/HtmlField'

export const fieldComponentByType: {
  [type: string]: FunctionComponent<any & EditorFormFieldProps>
} = {
  [EditorFormFieldType.Text]: InputField,
  [EditorFormFieldType.Select]: SelectField,
  [EditorFormFieldType.Reference]: ReferenceField,
  [EditorFormFieldType.Switch]: SwitchField,
  [EditorFormFieldType.Date]: DateTimeField,
  [EditorFormFieldType.Image]: ImageField,
  [EditorFormFieldType.DynamicList]: ListField,
  [EditorFormFieldType.Group]: GroupField,
  [EditorFormFieldType.Relationship]: RelationshipField,
  [EditorFormFieldType.External]: ExternalField,
  [EditorFormFieldType.Html]: HtmlField,
  [EditorFormFieldType.Number]: props => (
    <InputField type="number" {...props} />
  ),
}

export const getFieldComponentByType = (fieldType: EditorFormFieldType) =>
  fieldComponentByType[fieldType] ||
  fieldComponentByType[EditorFormFieldType.Text]
