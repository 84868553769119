import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'

import { DashboardCard } from '../DashboardCard'

const LoadingActiveKeyframes = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
`

const StyledLoadingCard = styled(DashboardCard)`
  animation: ${LoadingActiveKeyframes} 1s infinite ease-in-out;
`

interface LoadingCardProps {
  className?: string
  label: string
  icon: string
  type: string
}

export const LoadingCard: FunctionComponent<LoadingCardProps> = ({
  className,
  label,
  icon,
  type,
}) => (
  <StyledLoadingCard
    className={className}
    label={label}
    icon={icon}
    type={type}
  />
)
