import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Loading } from '@humancollective/build-ui'

import { AuthContext } from '../../contexts/AuthContext'

const SignOutPage: React.FunctionComponent = () => {
  const history = useHistory()
  const auth = useContext(AuthContext)

  React.useEffect(() => {
    const handleSignOut = async () => {
      try {
        await auth.signOut()
      } catch (error) {}
      history.push('/')
    }
    handleSignOut()
  }, [auth, history])

  return <Loading />
}

export default SignOutPage
