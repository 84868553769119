import styled from 'styled-components'

import { rhythm, scaleFontSize } from '../../style/typography'

export const Label = styled.label`
  display: block;
  padding-bottom: ${rhythm(1 / 6)}px;
  ${scaleFontSize(-1)};
  pointer-events: none;
  color: #757575;
  letter-spacing: 0.4px;
`
