import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useLoading } from '@humancollective/build-ui'

import { AuthContext } from '../../contexts/AuthContext'
import { CollectionListTemplate } from '../../templates/ListTemplate/CollectionList'
import { ListTemplateRow } from '../../templates/ListTemplate/ListTemplateRow'

export interface ListFormResponsesRouteParams {
  dashboardId?: string
  accountId: string
}

const FormsList: React.FunctionComponent = () => {
  const { dashboardId, accountId } = useParams<ListFormResponsesRouteParams>()
  const { getApp } = useContext(AuthContext)

  const app = getApp({
    firebaseConfig: {
      apiKey: 'AIzaSyBRxwelt7v1OstYbuc6YXwhyPj0JNd0AhI',
      authDomain: 'human-tools-production.firebaseapp.com',
      databaseURL: 'https://human-tools-production.firebaseio.com',
      projectId: 'human-tools-production',
      storageBucket: 'human-tools-production.appspot.com',
      messagingSenderId: '572572490447',
      appId: '1:572572490447:web:5da4c629344f50b8b975ba',
    },
    tokenUrl: 'https://hmn.to/auth/',
  })

  useLoading(!!app)

  return app ? (
    <CollectionListTemplate
      getQueryRef={() =>
        app
          .firestore()
          .collection('form-responses')
          .where('formRelayId', '==', accountId)
      }
      renderItem={(doc: any) => (
        <ListTemplateRow
          key={doc.id}
          label={doc.responseData.firstName}
          subtitle={doc.responseData.notes}
          to={`/d/${dashboardId}/form-relays/${accountId}/response/${doc.id}/`}
        />
      )}
    />
  ) : null
}

export default FormsList
