export const BASE_RADIUS = 6
export const INPUT_COLOR = '#495057'
export const palette = {
  primary: '#072bff',
  primaryHover: '#0c2ace',
}

export enum Palette {
  Black = '#000',
  OffBlack = '#242424',
  White = '#fff',
  OffWhite = '#f5f5f5',
  Red = '#ed563b',
  Green = '#3caea3',
  Blue = '#1f639b',
  Yellow = '#f6d55c',
}

export enum IntentPalette {
  Primary = Palette.Blue,
  Success = Palette.Green,
  Warning = Palette.Yellow,
  Danger = Palette.Red,
}

export enum FontStack {
  UI = 'system, sans-serif',
  SansSerif = 'system, sans-serif',
  Serif = 'Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif',
  Monospace = 'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace',
}

export const BASE_FONT_SIZE = 16
export const BASELINE_HEIGHT = 24
export const BASE_FONT_WEIGHT = 300
export const HEADING_FONT_WEIGHT = 600
export const STRONG_FONT_WEIGHT = 600

// see https://spencermortensen.com/articles/typographic-scale/
export const FONT_SCALE_INTERVAL: [number, number] = [2, 5]
