import React, { useState } from 'react'
import { Label } from '@humancollective/build-ui'

import { StyledSwitchFieldFormGroup } from './style'

export interface SwitchFieldProps {
  label?: string
  inverted?: boolean
  value?: boolean
  className?: string
  onChangeValue: (value: boolean) => void | Promise<void>
}

export const SwitchField: React.FunctionComponent<SwitchFieldProps> = ({
  label,
  className,
  inverted,
  value,
  onChangeValue,
}) => {
  const [focused, setFocused] = useState(false)
  return (
    <StyledSwitchFieldFormGroup className={className} focused={focused}>
      <div>
        {label && (
          <Label className="switchField-form-group__label">{label}</Label>
        )}
        <input
          className="switchField-form-group__input"
          type="checkbox"
          checked={inverted ? !value : value}
          onChange={e =>
            onChangeValue(inverted ? !e.target.checked : e.target.checked)
          }
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
    </StyledSwitchFieldFormGroup>
  )
}
