import React, { useEffect, useCallback } from 'react'
import { useAuth, useAuthedDocument } from '@humancollective/build-firebase'
import firebase from 'firebase/app'
import { useAuth0 } from '@auth0/auth0-react'

interface SignInOptions {
  url: string
  appname?: string
}

const signInWithToken = async (
  token: string,
  { url, appname }: SignInOptions
) => {
  const response = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  })
  const { firebaseToken } = await response.json()
  await firebase
    .app(appname)
    .auth()
    .signInWithCustomToken(firebaseToken)
}

export const AuthContext = React.createContext({
  signIn: async (_options: SignInOptions) => {},
  signOut: async () => {},
  getApp: (_collection: any) => undefined as firebase.app.App | undefined,
  isAdmin: undefined as boolean | undefined,
})

export const AuthContextProvider: React.FunctionComponent = ({ children }) => {
  const authZero = useAuth0()
  const dashboardAuth = useAuth(firebase.app('dashboard'))

  const dashboardPermissions = useAuthedDocument(uid =>
    firebase
      .app('dashboard')
      .firestore()
      .collection('permissions')
      .doc(uid)
  )
  const isAdmin = dashboardPermissions
    ? !!dashboardPermissions.admin
    : undefined

  const signIn = useCallback(
    async (options: SignInOptions) => {
      const token = await authZero.getAccessTokenSilently()
      signInWithToken(token, options)
    },
    [authZero]
  )

  const signInOnLoad = (appname: string, url: string, user: any) => {
    if (!user) {
      signIn({ appname, url })
    }
  }

  const getApp = (collection: any) => {
    const appName = collection.firebaseConfig.projectId

    // get app if it exists
    let app = firebase.apps.find(({ name }) => name === appName)
    if (app) return app

    // if it doesn't exist, then initialize it
    if (!app) {
      app = firebase.initializeApp(collection.firebaseConfig, appName)
    }

    app
      .auth()
      .onAuthStateChanged((user: any) =>
        signInOnLoad(app?.name || '', collection.tokenUrl, user)
      )
    return app
  }

  const signOut = useCallback(async () => {
    Promise.all(firebase.apps.map(a => a.auth().signOut()))
    authZero.logout()
  }, [authZero])

  useEffect(() => {
    if (!authZero.isLoading && !authZero.isAuthenticated) {
      authZero.loginWithRedirect()
    }
  })

  useEffect(() => {
    if (!authZero.isLoading && !dashboardAuth) {
      signIn({
        appname: 'dashboard',
        url:
          'https://us-central1-human-build-production.cloudfunctions.net/humanBuildGetToken',
      })
    }
  }, [signIn, authZero, dashboardAuth])

  return (
    <AuthContext.Provider value={{ getApp, signIn, signOut, isAdmin }}>
      {children}
    </AuthContext.Provider>
  )
}
