import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledBreadcrumbs = styled.div<{ separator?: string }>`
  .breadcrumbs {
    &__link {
      background-color: transparent;
      text-underline-position: under;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.03em;
      font-size: 14px;
      color: black;

      &.is--label {
        color: #444;
      }

      &:after {
        content: "${({ separator }) => separator ?? '/'}";
        display: inline-block;
        margin-left: 12px;
        margin-right: 12px;
        color: #aaa;
        cursor: default;
        pointer-events: none;
      }
      &:last-child:after {
        display: none;
      }
    }
  }
`

export interface BreadcrumbsProps {
  className?: string
  items: { label: string; to?: string }[]
  separator?: string
  onClick?: () => void
}

export const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = ({
  className,
  items,
}) => (
  <StyledBreadcrumbs className={className}>
    {items.map(({ label, to }) =>
      to ? (
        <Link key={label} className="breadcrumbs__link" to={to}>
          {label}
        </Link>
      ) : (
        <span key={label} className="breadcrumbs__link is--label">
          {label}
        </span>
      )
    )}
  </StyledBreadcrumbs>
)
