import firebase from 'firebase/app'

export const buildCollectionRef = (path: string, app?: firebase.app.App) => {
  const firebaseApp = app || firebase.app()
  let ref = firebaseApp.firestore() as unknown
  const parts = path.split('.')
  parts.forEach((part, index) => {
    const type = index % 2 === 0 ? 'collection' : 'doc'
    ref = (ref as any)[type](part)
  })
  return ref as
    | firebase.firestore.CollectionReference
    | firebase.firestore.Query
}
