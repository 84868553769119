import React, { useState, useEffect } from 'react'
import { firestore } from 'firebase/app'
import { useAuthedDocument } from '@humancollective/build-firebase'
import { Button, useLoading } from '@humancollective/build-ui'

import { ObjectEditor } from '.'

interface DocumentObjectEditorProps {
  getQueryRef: (
    firebaseUserId: string,
    dependencies?: any[] | undefined,
  ) => firestore.DocumentReference
}

export const DocumentObjectEditor: React.FunctionComponent<DocumentObjectEditorProps> = ({
  getQueryRef,
}) => {
  const values = useAuthedDocument(getQueryRef, { defaultValue: {} })
  const [code, setCode] = useState<string>()

  useEffect(() => {
    if (values) {
      setCode(JSON.stringify(values, null, 2))
    }
  }, [values])
  useLoading(!!code)

  const handleSave = async () => {
    const value = JSON.parse(code || '{}')
    getQueryRef('').set(value)
  }

  return code ? (
    <>
      <ObjectEditor value={code} onChange={setCode} />
      <br />
      <Button onClick={handleSave}>Save</Button>
      <br />
      <br />
    </>
  ) : null
}
