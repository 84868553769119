import { rhythm } from '@humancollective/build-ui'
import styled from 'styled-components'

import { DashboardCard } from '../DashboardCard'

export const StyledCollectionCard = styled(DashboardCard)`
  .collection-card {
    &__count {
      color: #a3adc1;
    }
    &__examples {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      margin-top: ${rhythm()}px;
      color: #636c82;
      align-items: center;
      &__icon {
        margin-left: ${rhythm(-4 / 3)}px;
        margin-right: ${rhythm(1 / 2)}px;
        box-shadow: 0 0 0 1px white;
        &:first-of-type {
          margin-left: 0;
        }
        &:nth-of-type(1) {
          z-index: 3;
        }
        &:nth-of-type(2) {
          z-index: 2;
        }
        &:nth-of-type(3) {
          z-index: 1;
        }
      }
    }
  }
`
