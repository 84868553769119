import React from 'react'

import { Loading } from '../../components'
import { useLoadingStack } from './useLoadingStack'

interface UIContextValue {
  trackLoading?: () => () => void
}

export const UIContext = React.createContext({} as UIContextValue)

export const UIContextProvider: React.FunctionComponent = ({ children }) => {
  const { loading, trackLoading } = useLoadingStack()

  return (
    <UIContext.Provider value={{ trackLoading }}>
      {loading && <Loading />}
      {children}
    </UIContext.Provider>
  )
}
