import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import styled from 'styled-components'
import { RichEditor } from '@humancollective/build-editor'
import { Grid } from '@humancollective/build-grid'
import { Breadcrumbs } from '@humancollective/build-ui'
import {
  buildDocumentRef,
  useAuthedDocument,
} from '@humancollective/build-firebase'

import { AuthContext } from '../../contexts/AuthContext'
import { viewDashboardRoute } from '../dashboards'
import { listPostsRoute } from './router'

export interface EditPostRouteParams {
  dashboardId?: string
  collectionId: string
  docId: string
}

const StyledEditDocument = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .edit-document__editor {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

const EditDocument: React.FunctionComponent = () => {
  const { dashboardId, collectionId, docId } = useParams<EditPostRouteParams>()
  const { getApp } = useContext(AuthContext)
  const collection = useAuthedDocument(() =>
    firebase
      .app('dashboard')
      .firestore()
      .collection('apps')
      .doc(collectionId)
  )

  const app = useMemo(() => (collection ? getApp(collection) : undefined), [
    collection,
    getApp,
  ])

  return app ? (
    <StyledEditDocument>
      <Grid.Container>
        <Grid.Inner withMargins>
          <Breadcrumbs
            items={[
              {
                label: 'Dashboard',
                to: dashboardId ? viewDashboardRoute({ dashboardId }) : '/',
              },
              {
                label: 'Posts',
                to: listPostsRoute({ dashboardId, collectionId }),
              },
              { label: 'Edit Post' },
            ]}
          />
        </Grid.Inner>
      </Grid.Container>
      <br />
      <RichEditor
        className="edit-document__editor"
        documentRef={buildDocumentRef(
          `${collection?.firebasePath}.${docId}`,
          app
        )}
      />
    </StyledEditDocument>
  ) : null
}

export default EditDocument
