import React, { useState, useEffect } from 'react'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

export const RecaptchaContext = React.createContext({ recaptchaToken: '' })

export const RecaptchaContextProvider: React.FunctionComponent = ({
  children,
}) => {
  const [recaptchaToken, setRecaptchaToken] = useState('')

  useEffect(() => {
    loadReCaptcha('6Lf4BMkZAAAAAB0-xYjBdIxb_0c2SgeFzZmWIpDs')
  }, [])

  return (
    <RecaptchaContext.Provider value={{ recaptchaToken }}>
      <ReCaptcha
        sitekey="6Lf4BMkZAAAAAB0-xYjBdIxb_0c2SgeFzZmWIpDs"
        action="contact"
        verifyCallback={setRecaptchaToken}
      />
      {children}
    </RecaptchaContext.Provider>
  )
}
