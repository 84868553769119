import React, { FunctionComponent } from 'react'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { useLoading } from '@humancollective/build-ui'
import { firestore } from 'firebase/app'

import { MetricTemplate, SharedMetricTemplateProps } from './index'

type CombinedProps = SharedMetricTemplateProps & {
  getQueryRef: (
    firebaseUserId: string,
    dependencies?: any[] | undefined
  ) => firestore.CollectionReference | firestore.Query
}

export const CollectionMetricTemplate: FunctionComponent<CombinedProps> = ({
  getQueryRef,
  ...props
}) => {
  const data = useAuthedCollection(getQueryRef)
  useLoading(!!data)
  return data ? <MetricTemplate data={data} {...props} /> : null
}
