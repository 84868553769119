import React from 'react'
import { Helmet } from 'react-helmet'

export const HelmetViewport = () => (
  <Helmet key="helmet-viewport">
    <meta name="apple-mobile-web-app-status-bar-style" content="black" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />
  </Helmet>
)
