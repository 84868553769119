import React from 'react'
import { Grid, gridColumn } from '@humancollective/build-grid'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Card, rhythm } from '@humancollective/build-ui'

import { requestRoute } from '../request'

const StyledWelcomePage = styled(Grid.Container)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-contents: center;

  .welcome-page {
    &__card {
      p,
      h5 {
        margin-top: ${rhythm(1 / 2)}px;
        max-width: ${gridColumn(4)};
      }
    }
  }
`

const WelcomePage = () => (
  <StyledWelcomePage>
    <Grid.Inner>
      <Card className="welcome-page__card">
        <h1>
          <span role="img" aria-label="wave">
            👋
          </span>{' '}
          Welcome.
        </h1>
        <p>
          With Human.build, you'll be able to manage your business from
          anywhere. Now that you have an account, let's get you set up with
          access!
        </p>
        <h5>
          Please <Link to={requestRoute()}>submit a request</Link> for the
          account(s) you need access to using the form below.
        </h5>
      </Card>
    </Grid.Inner>
  </StyledWelcomePage>
)

export default WelcomePage
