import styled from 'styled-components'
import { rhythm } from '@humancollective/build-ui'

import { EditorSyncStatus } from '../../types'

export const colorByStatus: { [key in EditorSyncStatus]: string } = {
  [EditorSyncStatus.Loading]: '#576574',
  [EditorSyncStatus.Live]: '#0771ff',
  [EditorSyncStatus.Error]: '#ef5253',
  [EditorSyncStatus.Modified]: '#feca57',
  [EditorSyncStatus.Syncing]: '#ffa043',
}

export const StyledSyncStatus = styled.div<{
  status: EditorSyncStatus
  show: boolean
}>`
  display: flex;
  align-items: center;
  color: #7d7d7d;
  font-weight: 500;
  letter-spacing: 0.5px;
  flex-grow: 0;
  font-size: 12px;
  transition: height 250ms ease, opacity 250ms ease;

  ${({ show }) =>
    show
      ? `
    height: ${rhythm()}px;
    opacity: 1;
  `
      : `
    height: 0;
    opacity: 0;
  `}

  &:before {
    content: '';
    display: block;
    background-color: ${({ status }) => colorByStatus[status]};
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 8px;
    transition: background-color ease 500ms;
  }
`
