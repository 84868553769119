import React, { FunctionComponent } from 'react'
import { useAuthedDocument } from '@humancollective/build-firebase'
import { app } from 'firebase/app'
import styled from 'styled-components'

import { DisplayIcon } from '../display/Icon'

const StyledUserMenu = styled.div`
  display: flex;
  align-items: center;

  .user-menu {
    &__avatar {
      margin-right: 12px;
    }
    &__name {
      color: #11142d;
      font-weight: 600;
    }
  }
`

export interface UserMenuProps {
  className?: string
}

export const UserMenu: FunctionComponent<UserMenuProps> = (props) => {
  const user = useAuthedDocument((uid: string) =>
    app('dashboard').firestore().collection('users').doc(uid)
  )

  return (
    <StyledUserMenu {...props}>
      <DisplayIcon
        className="user-menu__avatar"
        abbr="M"
        size={40}
        url={user?.avatar}
        background="#072bff"
        round
      />
      <div>
        <div className="user-menu__name">{user?.name}</div>
        <div>Administrator</div>
      </div>
    </StyledUserMenu>
  )
}
