import React, { createContext, FunctionComponent } from 'react'
import firebase from 'firebase'

import { useStateDictionary } from '../../hooks/useStateDictionary'

type App = firebase.app.App
type Value = firebase.User | null | undefined
type Listener = () => void

interface UsersContextValue {
  monitor: (app: App) => void
  clear: () => void
  values: { [appId: string]: Value }
  get: (id: string) => Value | null
}

export const UsersContext = createContext<UsersContextValue>({
  monitor: () => {},
  clear: () => {},
  get: () => {
    return null
  },
  values: {},
})

export const UsersContextProvider: FunctionComponent = ({ children }) => {
  const listeners = useStateDictionary<Listener>()
  const values = useStateDictionary<Value>()

  const monitor = (app: firebase.app.App) => {
    if (!listeners.get(app.name)) {
      const off = firebase
        .auth(app)
        .onAuthStateChanged(u => values.set(app.name, u))
      listeners.set(app.name, off)
    }
  }

  const clear = () => {
    Object.values(listeners.values).map(off => off())
    listeners.clear()
    values.clear()
  }

  return (
    <UsersContext.Provider
      value={{
        monitor,
        clear,
        get: values.get,
        values: values.values,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}
