import React, { FunctionComponent, ReactNode } from 'react'
import get from 'lodash/get'
import set from 'lodash/set'

import { EditorFormField, EditorFormFieldProps } from '../../types'
import { EditorFormHeading } from '../../components/Heading'
import { getNestableFieldComponentByType } from '../../utils/nestableFieldComponentByType'
import { StyledGroupField } from './style'

export const getFormField = (
  { fieldType, name, ...rest }: EditorFormField & EditorFormFieldProps,
  index: number
) => {
  const Component = getNestableFieldComponentByType(fieldType)
  return (
    <Component
      key={name || `editor-form-field--${index}`}
      name={name}
      {...rest}
    />
  )
}

export interface GroupFieldProps {
  className?: string
  label: string | ReactNode
  fields: EditorFormField[]
  description?: string
  value: any
  onChangeValue: (nextValue: any) => void | Promise<void>
}

export const GroupField: FunctionComponent<GroupFieldProps &
  EditorFormFieldProps> = ({
  values,
  documentRef,
  value,
  onChangeValue,
  className,
  label,
  description,
  fields,
}) => {
  const [expanded, setExpanded] = React.useState(false)
  const toggleExpanded = () => setExpanded(!expanded)

  const handleChange = (name: string) => (change: any) => {
    const nextValue = set({ ...value }, name, change)
    onChangeValue(nextValue)
  }

  return (
    <StyledGroupField expanded={expanded} className={className}>
      <button
        onClick={toggleExpanded}
        className="expandable-form-group__heading"
        type="button"
      >
        {typeof label === 'string' ? (
          <EditorFormHeading label={label} description={description} />
        ) : (
          label
        )}
      </button>
      <div className="expandable-form-group__fields">
        {fields.map((field, index) => (
          <div className="expandable-form-group__fields__field">
            {getFormField(
              {
                ...(field as EditorFormField),
                value: get(value, field.name),
                onChangeValue: handleChange(field.name),
                documentRef,
                values,
              },
              index
            )}
          </div>
        ))}
      </div>
    </StyledGroupField>
  )
}
