import { createGlobalStyle } from 'styled-components'

export const PrismCSS = createGlobalStyle`
  /* Generated with http://k88hudson.github.io/syntax-highlighting-theme-generator/www */
  /* http://k88hudson.github.io/react-markdocs */
  /**
   * @author k88hudson
   *
   * Based on prism.js default theme for JavaScript, CSS and HTML
   * Based on dabblet (http://dabblet.com)
   * @author Lea Verou
   */
  /*********************************************************
  * General
  */
  pre[class*="language-"],
  code[class*="language-"] {
    color: #242424;
    text-shadow: none;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
  pre[class*="language-"]::selection,
  code[class*="language-"]::selection,
  pre[class*="language-"]::mozselection,
  code[class*="language-"]::mozselection {
    text-shadow: none;
    background: #eceeff;
  }
  @media print {
    pre[class*="language-"],
    code[class*="language-"] {
      text-shadow: none;
    }
  }
  pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    background: #f8f9fb;
  }
  :not(pre) > code[class*="language-"] {
    padding: .1em .3em;
    border-radius: .3em;
    color: #db4c69;
    background: #f9f2f4;
  }
  /*********************************************************
  * Tokens
  */
  .namespace {
    opacity: .7;
  }
  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #93a1a1;
  }
  .token.punctuation {
    color: #a2abbe;
  }
  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #072bff;
  }
  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #242424;
  }
  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #a67f59;
    background: #f8f9fb;
  }
  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #0a1fc0;
  }
  .token.function {
    color: #dd4a68;
  }
  .token.regex,
  .token.important,
  .token.variable {
    color: #ee9900;
  }
  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }
  /*********************************************************
  * Line highlighting
  */
  pre[data-line] {
    position: relative;
  }
  pre[class*="language-"] > code[class*="language-"] {
    position: relative;
    z-index: 1;
  }
  .line-highlight {
    position: absolute;
    left: 0;
    right: 0;
    padding: inherit 0;
    margin-top: 1em;
    background: #eceeff;
    box-shadow: inset 5px 0 0 #990055;
    z-index: 0;
    pointer-events: none;
    line-height: inherit;
    white-space: pre;
  }
`
