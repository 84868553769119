import styled from 'styled-components'
import { rhythm, BASE_RADIUS } from '@humancollective/build-ui'

const StyledDraftBanner = styled.div`
  padding-top: ${rhythm(1 / 3)}px;
  padding-bottom: ${rhythm(1 / 3)}px;
  border-radius: ${BASE_RADIUS}px;
  background-color: #f8f9fb;

  .draft-banner {
    &__container {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    &__label {
      flex: 1;
      margin-top: ${rhythm(1 / 2)}px;
      margin-bottom: ${rhythm(1 / 2)}px;
    }
    &__controls {
      margin-top: ${rhythm(1 / 2)}px;
      margin-bottom: ${rhythm(1 / 2)}px;
      button {
        margin-left: ${rhythm(1 / 4)}px;
        margin-right: ${rhythm(1 / 4)}px;
      }
    }
  }
`

export default StyledDraftBanner
