import styled from 'styled-components'
import { BASE_RADIUS, rhythm } from '@humancollective/build-ui'

export const StyledGroupField = styled.div<{ expanded: boolean }>`
  border: 1px solid #e5e7ea;
  border-radius: ${BASE_RADIUS}px;

  .expandable-form-group {
    &__heading {
      user-select: none;
      cursor: pointer;
      transition: background-color 400ms ease;
      border-radius: ${BASE_RADIUS}px;
      padding: 0 ${rhythm()}px;
      width: 100%;
      border: none;
      text-align: left;
      background-color: #ffffff;
      &:hover {
        background-color: #fafcff;
      }
      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px #cae2ff;
      }
    }
    &__fields {
      overflow: hidden;
      transition: opacity 400ms ease;
      display: ${({ expanded }) => (expanded ? 'block' : 'none')};
      padding: ${rhythm()}px;
      padding-top: 0;
      &__field {
        margin-top: ${rhythm()}px;
      }
    }
  }
`
