import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { scaleFontSize, rhythm } from '@humancollective/build-ui'

export const StyledEditorFormHeading = styled.div`
  margin-top: ${rhythm()}px;
  margin-bottom: ${rhythm()}px;

  .editor-form-heading {
    &__description {
      ${scaleFontSize(-1)};
      color: #757575;
    }
  }
`

interface EditorFormHeadingProps {
  label: string
  description?: string
}

export const EditorFormHeading: FunctionComponent<EditorFormHeadingProps> = ({
  label,
  description,
}) => (
  <StyledEditorFormHeading>
    <h5 className="editor-form-heading__title">{label}</h5>
    {description && (
      <div className="editor-form-heading__description">{description}</div>
    )}
  </StyledEditorFormHeading>
)
