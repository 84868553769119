import styled from 'styled-components'

export const StyledHtmlField = styled.div`
  .build-field-html {
    &__editable {
      width: 100%;
      outline: none;
      transition: border-left-color ease 500ms;
      display: block;

      a {
        color: #072bff;
      }

      &::selection,
      & *::selection {
        background-color: #eceeff;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  p {
    padding-top: 8px;
    padding-bottom: 8px;
  }
`
