import React, { FunctionComponent, useState, useMemo } from 'react'
import { useAuthedCollection } from '@humancollective/build-firebase'
import {
  Card,
  Button,
  rhythm,
  useLoading,
  Imgix,
} from '@humancollective/build-ui'

import { EditorFormFieldProps } from '../../types'
import { SelectField } from '../SelectField'
import { StyledRelationshipField } from './style'

export interface RelationshipFieldProps {
  className?: string
  label: string
}

export const RelationshipField: FunctionComponent<RelationshipFieldProps &
  EditorFormFieldProps> = ({ label, documentRef, className }) => {
  const app = documentRef.firestore.app
  const [selected, setSelected] = useState('')

  const toCollection = 'shows'

  const linksRef = app
    .firestore()
    .collection('permissions')
    .doc(documentRef.id)
    .collection(toCollection)
  const links = useAuthedCollection(linksRef)

  const valuesRef = app.firestore().collection(toCollection)
  const values = useAuthedCollection<{
    name: string
    coverImage: string
    startTime: any
  }>(valuesRef)

  const valueOptions = useMemo(() => {
    if (!values || !links) {
      return []
    }
    const linkIds = links.map(({ id }) => id)
    return values
      .filter(({ id }) => !linkIds.includes(id))
      .map(v => ({ value: v.id, label: v.name }))
  }, [values, links])

  useLoading(!!links, !!values)

  return links && values && valueOptions ? (
    <StyledRelationshipField className={className}>
      <h4>{label}</h4>
      <br />
      {links.map(({ id }) => {
        const match = values.find(v => v.id === id)
        return match ? (
          <Card key={id} className="relationship-field__link">
            <Imgix
              className="relationship-field__link__image"
              path={match.coverImage}
              options={{
                width: rhythm(3),
                height: rhythm(3),
              }}
            />
            <div>
              <div className="relationship-field__link__title">
                {match.name || '(not found)'}
              </div>
              <div className="relationship-field__link__subtitle">
                {match.startTime?.toDate().toLocaleString()}
              </div>
            </div>
          </Card>
        ) : null
      })}
      <div className="relationship-field__add-connection">
        <SelectField
          className="relationship-field__add-connection__select"
          value={selected}
          onChangeValue={setSelected}
          options={valueOptions}
        />
        <Button appearance="outline" size="small">
          Add
        </Button>
      </div>
    </StyledRelationshipField>
  ) : null
}
