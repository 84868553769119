import React, { FunctionComponent, useState, useEffect } from 'react'
import { Label } from '@humancollective/build-ui'
import { HtmlField as HtmlFieldBase } from '@humancollective/build-field-html'
import { StyledHtmlField } from './style'

export interface HtmlFieldProps {
  value: string
  onChangeValue: (value: string) => void | Promise<void>
  label?: string
  className?: string
  multiline?: boolean
  StatusComponent?: FunctionComponent<{ focused: boolean }>
  focused?: boolean
  setFocused?: (focused: boolean) => void | Promise<void>
}

export const HtmlField: FunctionComponent<HtmlFieldProps> = ({
  value,
  onChangeValue,
  setFocused,
  label,
  className,
}) => {
  const [internalFocused, setInternalFocused] = useState(false)

  useEffect(() => {
    if (setFocused) {
      setFocused(internalFocused)
    }
  }, [internalFocused, setFocused])

  return (
    <StyledHtmlField
      className={className}
      expanded={!!value || internalFocused}
      focused={internalFocused}
    >
      {label && <Label className="input-form-group__label">{label}</Label>}
      <HtmlFieldBase
        className="input-form-group__input"
        value={value}
        onChangeValue={onChangeValue}
        onFocus={() => setInternalFocused(true)}
        onBlur={() => setInternalFocused(false)}
      />
    </StyledHtmlField>
  )
}
