import React, { useState } from 'react'
import { firestore } from 'firebase/app'
import { ImageUploader } from '@humancollective/build-uploaders'
import { Label, Modal, Imgix } from '@humancollective/build-ui'
import { StyledImageField } from './style'

export interface ImageFieldProps {
  value: string
  onChangeValue: (nextValue: string) => void | Promise<void>
  documentRef: firestore.DocumentReference
  baseUrl?: string
  className?: string
  label?: string
  type?: string
  required?: boolean
}

export const ImageField: React.FunctionComponent<ImageFieldProps> = ({
  value,
  onChangeValue,
  documentRef,
  baseUrl,
  label,
  className,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const app = documentRef.firestore.app
  return (
    <StyledImageField className={className}>
      {showPreviewModal && (
        <Modal onClose={() => setShowPreviewModal(false)}>
          <Imgix
            className="switch-form-group__modal-preview"
            path={value}
            options={{ width: 720 }}
          />
        </Modal>
      )}
      {!!value && (
        <Imgix
          onClick={() => setShowPreviewModal(true)}
          className="switch-form-group__preview"
          path={value}
          options={{ width: 80 }}
        />
      )}
      <div className="switch-form-group__controls">
        {label && <Label className="switch-form-group__label">{label}</Label>}
        <ImageUploader
          app={app}
          baseUrl={baseUrl}
          onUploadSuccess={onChangeValue}
        />
      </div>
    </StyledImageField>
  )
}
