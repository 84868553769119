import React, { FunctionComponent } from 'react'
import styled, { keyframes } from 'styled-components'

import { Card } from '../Card'
import { rhythm } from '../../style/typography'

const showCurtain = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const showCard = keyframes`
  from {
    transform: translateY(${rhythm(6)}px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`

const StyledModal = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .modal {
    &__curtain {
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgb(190 198 210 / 0%);
      animation: ${showCurtain} 300ms ease forwards;
      backdrop-filter: blur(2px);
      backface-visibility: hidden;
      perspective: 1000;
      transform: translate3d(0, 0, 0);
      transform: translateZ(0);
    }
    &__contents {
      position: absolute;
      overflow: scroll;
      max-height: 90vh;
      max-width: 90vw;
    }
    &__card {
      background: white;
      animation: ${showCard} 200ms ease forwards;
    }
  }
`

interface ModalProps {
  onClose: () => void
  className?: string
}

export const Modal: FunctionComponent<ModalProps> = ({
  onClose,
  className,
  children,
}) => (
  <StyledModal className={className}>
    <div className="modal__curtain" onClick={onClose}></div>
    <div className="modal__contents" onClick={e => e.stopPropagation()}>
      <Card className="modal__card">{children}</Card>
    </div>
  </StyledModal>
)
