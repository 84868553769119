import { useState, useEffect, useMemo } from 'react'
import { firestore } from 'firebase/app'

import { DocumentRefGetter, makeDocumentRef } from '../utils/makeRef'
import { useAuth } from './useAuth'

export const useAuthedDocumentRef = (
  documentRef: DocumentRefGetter | firestore.DocumentReference,
  dependencies: any[] = [],
) => {
  const [reference, setReference] = useState<firestore.DocumentReference>()

  const app = useMemo(() => makeDocumentRef(documentRef).firestore.app, [
    documentRef,
  ])
  const firebaseUser = useAuth(app)

  useEffect(() => {
    if (firebaseUser) {
      setReference(makeDocumentRef(documentRef, firebaseUser.uid, dependencies))
    }
  }, [firebaseUser, ...dependencies])

  return reference
}
