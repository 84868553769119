import styled from 'styled-components'
import { INPUT_COLOR, palette, rhythm } from '@humancollective/build-ui'

export const StyledHtmlField = styled.div<{
  expanded: boolean
  focused: boolean
}>`
  // trick to maintain the bottom border when the input is not expanded
  box-shadow: inset 0 -1px #ced4db;

  .input-form-group {
    &__label {
      ${({ focused }) => (focused ? `color: ${palette.primary};` : '')}
      ${({ expanded }) =>
        expanded
          ? `
            transform: translateY(0);
            transition: color 250ms ease-out, transform 200ms ease-out;
          `
          : `
            transform: translateY(24px);
            transition: color 250ms ease-out, transform 200ms ease-out;
          `}
    }
  }
  .build-field-html__editable {
    display: block;
    width: 100%;
    background-color: transparent;
    padding: ${rhythm(1 / 6)}px 0;
    border: none;
    border-bottom: 1px solid #ced4db;
    color: ${INPUT_COLOR};
    opacity: ${({ expanded }) => (expanded ? 1 : 0)};
    transition: border-color 250ms ease-out;
    resize: vertical;
    &:focus {
      outline: none;
      border-bottom: 1px solid ${palette.primary};
    }
    &:disabled {
      opacity: 0.4;
    }
  }
`
