import { app } from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'

interface CreateAppArgs {
  label?: string
}

export const createApp = async (args: CreateAppArgs): Promise<string> => {
  if (process.env.REACT_APP_EMULATOR_URL) {
    app('dashboard')
      .functions()
      .useFunctionsEmulator(process.env.REACT_APP_EMULATOR_URL)
  }
  const callableCreateApp = app('dashboard')
    .functions()
    .httpsCallable('createApp')
  try {
    const { data } = await callableCreateApp(args)
    return data.id
  } catch (error) {
    throw error
  }
}
