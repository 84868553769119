import React from 'react'
import firebase from 'firebase/app'
import { Button, ListRow } from '@humancollective/build-ui'
import { useHistory } from 'react-router-dom'

import { CollectionListTemplate } from '../../templates/ListTemplate/CollectionList'
import { editDashboardRoute } from './router'

const AppList: React.FunctionComponent = () => {
  const history = useHistory()

  const addItem = async () => {
    const docRef = await firebase
      .app('dashboard')
      .firestore()
      .collection('dashboards')
      .add({ name: 'Untitled', apps: [] })
    history.push(editDashboardRoute({ dashboardId: docRef.id }))
  }

  return (
    <CollectionListTemplate
      getQueryRef={() =>
        firebase
          .app('dashboard')
          .firestore()
          .collection('dashboards')
      }
      controls={<Button onClick={addItem}>Add</Button>}
      renderItem={(doc: any) => (
        <ListRow
          key={doc.id}
          label={doc.name}
          onClick={() => history.push(editDashboardRoute({ dashboardId: doc.id }))}
        />
      )}
    />
  )
}

export default AppList
