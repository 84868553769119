import React from 'react'
import { Grid, gridColumn } from '@humancollective/build-grid'
import styled from 'styled-components'
import { Card, rhythm } from '@humancollective/build-ui'

import { ContactForm } from '../../components/form/ContactForm'

const StyledRequestPage = styled(Grid.Container)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-contents: center;

  .request-page {
    &__card {
      p,
      h5 {
        margin-top: ${rhythm(1 / 2)}px;
        max-width: ${gridColumn(4)};
      }
    }
  }
`

const RequestPage = () => (
  <StyledRequestPage>
    <Grid.Inner>
      <Card className="request-page__card">
        <h1>
          <span role="img" aria-label="mail">
            ✉️
          </span>{' '}
          Account Request
        </h1>
        <p>
          Use this form to request changes to your account or organization.{' '}
          <strong>
            Most changes require authorization from your account administrator,
            but we will process them as quickly as possible.
          </strong>
        </p>
        <br />
        <ContactForm />
      </Card>
    </Grid.Inner>
  </StyledRequestPage>
)

export default RequestPage
