import React, { FunctionComponent } from 'react'
import { Button } from '@humancollective/build-ui'

import chatIcon from '../../../assets/jollycons/chat.svg'
import { DashboardCard } from '../DashboardCard'

interface FormRelayCardProps {
  label: string
  className?: string
  onClick?: () => void
}

export const FormRelayCard: FunctionComponent<FormRelayCardProps> = ({
  label,
  className,
  onClick,
}) => (
  <DashboardCard
    className={className}
    label={label}
    icon={chatIcon}
    type="form relay"
    bottomContents={
      <Button onClick={onClick} size="small" appearance="ghost">
        View &rarr;
      </Button>
    }
  />
)
