import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Grid } from '@humancollective/build-grid'

import { palette } from '../../theme'
import { rhythm, scaleFontSize } from '../../style'
import { DisplayIcon } from '../display/Icon'

const StyledListTempalateRow = styled.div<{ statusColor: string }>`
  display: block;
  padding-top: ${rhythm(1 / 2)}px;
  padding-bottom: ${rhythm(1 / 2)}px;
  border-bottom: 1px solid #f5f5f5;
  transition: background-color 400ms ease;
  background-color: white;
  cursor: pointer;

  .list-row {
    display: flex;
    align-items: center;
    &__subtitle {
      ${scaleFontSize(-1)};
      color: #9198ab;
    }
    &__icon {
      margin-right: ${rhythm(1 / 2)}px;
    }
    &__info {
      flex: 1;
    }
    &__status {
      ${scaleFontSize(-1)};
      color: ${({ statusColor }) => statusColor};
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }

  .list-row__label,
  .list-row__subtitle {
    transition: color 400ms ease;
  }

  &:hover {
    background-color: #fafcff;
    .list-row__label,
    .list-row__subtitle {
      color: ${palette.primary};
    }
  }

  &:last-of-type {
    border-bottom: none;
  }
`

export interface ListRowProps {
  label: string
  subtitle?: string
  statusLabel?: string
  statusColor?: string
  modified?: Date
  onClick?: () => void
  icon?: {
    background?: string
    abbr?: string
    url?: string
  }
}

export const ListRow: FunctionComponent<ListRowProps> = ({
  label,
  subtitle,
  icon,
  onClick,
  statusLabel,
  statusColor = '#9198ab',
}) => (
  <StyledListTempalateRow onClick={onClick} statusColor={statusColor}>
    <Grid.Container>
      <Grid.Inner withMargins>
        <div className="list-row">
          {icon && <DisplayIcon className="list-row__icon" {...icon} />}
          <div className="list-row__info">
            <h5 className="list-row__label">{label}</h5>
            {!!subtitle && <div className="list-row__subtitle">{subtitle}</div>}
          </div>
          {!!statusLabel && (
            <div className="list-row__status">{statusLabel}</div>
          )}
        </div>
      </Grid.Inner>
    </Grid.Container>
  </StyledListTempalateRow>
)
