import React, { FunctionComponent } from 'react'
import { Button } from '@humancollective/build-ui'
import { Grid } from '@humancollective/build-grid'

import StyledDraftBanner from './DraftBanner.style'

interface DraftBannerProps {
  publishDraft: () => void | Promise<void>
  clearDraft: () => void | Promise<void>
}

const DraftBanner: FunctionComponent<DraftBannerProps> = ({
  publishDraft,
  clearDraft,
}) => (
  <StyledDraftBanner>
    <Grid.Container>
      <Grid.Inner className="draft-banner__container">
        <div className="draft-banner__label">
          You are viewing a draft with unpublished changes.
        </div>
        <div className="draft-banner__controls">
          <Button onClick={publishDraft}>Publish Draft</Button>
          <Button appearance="outline" onClick={clearDraft}>
            Clear Changes
          </Button>
        </div>
      </Grid.Inner>
    </Grid.Container>
  </StyledDraftBanner>
)

export default DraftBanner
