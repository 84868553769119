import React, { FunctionComponent } from 'react'

interface IconProps {
  className?: string
}

export const IconDashboard: FunctionComponent<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    viewBox="0 0 21 22"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="nonzero"
      d="M12.924 1.37a.75.75 0 0 1 .102 1.493l-.102.007H5.657c-2.455 0-4.034 1.626-4.125 4.161l-.004.233v8.083c0 2.602 1.484 4.284 3.906 4.38l.223.005h8.604c2.457 0 4.034-1.62 4.124-4.153l.005-.232v-7.04a.75.75 0 0 1 1.493-.101l.007.102v7.039c0 3.39-2.14 5.772-5.395 5.88l-.234.005H5.657c-3.33 0-5.525-2.29-5.625-5.644l-.004-.241V7.264c0-3.392 2.142-5.78 5.395-5.89l.234-.004h7.267Zm2.117 6.83a.75.75 0 0 1 .195.96l-.062.092-2.929 3.78a.75.75 0 0 1-.964.193l-.092-.062-2.819-2.214-2.53 3.29a.75.75 0 0 1-.96.198l-.092-.061a.75.75 0 0 1-.199-.96l.061-.092 2.994-3.89a.75.75 0 0 1 .964-.195l.093.063 2.82 2.215 2.467-3.183a.75.75 0 0 1 1.053-.134ZM17.995.528a2.672 2.672 0 1 1 0 5.344 2.672 2.672 0 0 1 0-5.344Zm0 1.5a1.172 1.172 0 1 0 0 2.344 1.172 1.172 0 0 0 0-2.344Z"
    />
  </svg>
)
