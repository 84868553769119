import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import ViewPage, { ViewMetricRouteParams } from './View'

export const viewMetricRoute = ({
  dashboardId,
  metricId,
}: ViewMetricRouteParams) =>
  dashboardId ? `/d/${dashboardId}/m/${metricId}/` : `/m/${metricId}/`

const MetricRouter: FunctionComponent = () => (
  <Switch>
    <Route
      exact
      path={[
        viewMetricRoute({ dashboardId: ':dashboardId', metricId: ':metricId' }),
        viewMetricRoute({ metricId: ':metricId' }),
      ]}
    >
      <ViewPage />
    </Route>
  </Switch>
)

export default MetricRouter
