import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Grid } from '@humancollective/build-grid'
import { firestore } from 'firebase/app'

import { DocumentObjectEditor } from '../../components/code/ObjectEditor/DocumentObjectEditor'

interface ObjectEditorTemplateProps {
  getQueryRef: (
    firebaseUserId: string,
    dependencies?: any[] | undefined
  ) => firestore.DocumentReference
}

const StyledObjectEditorTemplate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fb;
`

const ObjectEditorTemplate: FunctionComponent<ObjectEditorTemplateProps> = ({
  getQueryRef,
}) => (
  <StyledObjectEditorTemplate>
    <Grid.Container>
      <Grid.Inner withMargins>
        <DocumentObjectEditor getQueryRef={getQueryRef} />
      </Grid.Inner>
    </Grid.Container>
  </StyledObjectEditorTemplate>
)

export default ObjectEditorTemplate
