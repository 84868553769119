import React, { useState } from 'react'
import { firestore } from 'firebase/app'
import { Moment } from 'moment'
import ReactDateTime from 'react-datetime'
import { Label } from '@humancollective/build-ui'
import { InputFormGroup, StyledDateTimeField } from './style'

export interface DateTimeFieldProps {
  label?: string
  showTime?: boolean
  className?: string
  value: string | firestore.Timestamp
  onChangeValue: (next: string | firestore.Timestamp) => void | Promise<void>
}

export const DateTimeField: React.FunctionComponent<DateTimeFieldProps> = ({
  value,
  onChangeValue,
  label,
  className,
  showTime,
}) => {
  const [focused, setFocused] = useState(false)

  const handleChange = (value: string | Moment) => {
    if (typeof value === 'string') {
      onChangeValue(value)
    } else {
      const date = value.toDate()
      const timestamp = firestore.Timestamp.fromDate(date)
      onChangeValue(timestamp)
    }
  }

  const dateValue =
    value && typeof value !== 'string' ? value.toDate() : undefined

  return (
    <StyledDateTimeField className={className}>
      <InputFormGroup expanded={!!value || focused} focused={focused}>
        {label && <Label className="input-form-group__label">{label}</Label>}
        <ReactDateTime
          value={dateValue}
          onChange={handleChange}
          timeFormat={!!showTime}
          inputProps={{
            className: 'input-form-group__input',
          }}
          onOpen={() => setFocused(true)}
          onClose={() => setFocused(false)}
        />
      </InputFormGroup>
    </StyledDateTimeField>
  )
}
