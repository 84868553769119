import React, { FunctionComponent } from 'react'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { firestore } from 'firebase/app'

import {
  ListTemplate,
  SharedListTemplateProps,
  SearchableTemplate,
} from './index'

type CombinedProps = SharedListTemplateProps &
  SearchableTemplate & {
    getQueryRef: (
      firebaseUserId: string,
      dependencies?: any[] | undefined
    ) => firestore.CollectionReference | firestore.Query
    getDraftQueryRef: (
      firebaseUserId: string,
      dependencies?: any[] | undefined
    ) => firestore.CollectionReference | firestore.Query
  }

export const CollectionListTemplate: FunctionComponent<CombinedProps> = ({
  getQueryRef,
  getDraftQueryRef,
  ...props
}) => {
  const data = useAuthedCollection(getQueryRef)
  const drafts = useAuthedCollection(getDraftQueryRef)
  return data ? <ListTemplate data={data} drafts={drafts} {...props} /> : null
}
