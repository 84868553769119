import React, {
  FunctionComponent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { StyledHtmlFieldSelectionMenu } from './style'
import MainMode from './modes/Main'
import AddLinkMode from './modes/AddLink'
import { SelectionMenuMode } from './types'

export interface HtmlFieldSelectionMenuProps {
  onClose: () => void
  triggerOnChange: () => void
  range?: Range
  editorRef: RefObject<HTMLDivElement | null>
}

export const HtmlFieldSelectionMenu: FunctionComponent<HtmlFieldSelectionMenuProps> = ({
  onClose,
  triggerOnChange,
  range,
}) => {
  const [mode, setMode] = useState(SelectionMenuMode.Main)
  const [cachedRange, setCachedRange] = useState(range?.cloneRange())

  useEffect(() => {
    if (range) {
      setCachedRange(range)
    }
  }, [range])

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, onClose)

  if (!cachedRange) {
    return null
  }

  const { top, left, width } = cachedRange.getBoundingClientRect()
  const position = { top, left: left + width / 2 }

  return (
    <StyledHtmlFieldSelectionMenu ref={ref} style={position}>
      {mode === SelectionMenuMode.Main && <MainMode setMode={setMode} />}
      {mode === SelectionMenuMode.AddLink && (
        <AddLinkMode
          setMode={setMode}
          range={cachedRange}
          triggerOnChange={triggerOnChange}
        />
      )}
    </StyledHtmlFieldSelectionMenu>
  )
}
