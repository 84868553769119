import React, { FunctionComponent } from 'react'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { firestore } from 'firebase/app'

import { GalleryTemplate, GalleryTemplateProps } from './index'

type CombinedProps = GalleryTemplateProps & {
  getQueryRef: (
    firebaseUserId: string,
    dependencies?: any[] | undefined
  ) => firestore.CollectionReference | firestore.Query
}

export const CollectionGalleryTemplate: FunctionComponent<CombinedProps> = ({
  getQueryRef,
  ...props
}) => {
  const data = useAuthedCollection(getQueryRef)
  return <GalleryTemplate {...props} data={data} />
}
