import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import {
  rhythm,
  BASE_RADIUS,
  Imgix,
  ImgixFitMode,
} from '@humancollective/build-ui'

const StyledDisplayIcon = styled.div<{
  background?: string
  size: number
  round: boolean
}>`
  background: ${({ background }) => background || '#f8f9fb'};
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: ${({ round }) => (round ? '50%' : `${BASE_RADIUS}px`)};
  overflow: hidden;
  border: 1px solid #f5f5f5;

  .display-icon {
    &__abbr {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 18px;
      font-weight: 900;
    }
  }
`

interface DisplayIconProps {
  size?: number
  round?: boolean
  className?: string
  background?: string
  abbr?: string
  url?: string
}

export const DisplayIcon: FunctionComponent<DisplayIconProps> = ({
  size = rhythm(2),
  round = false,
  className,
  background,
  abbr,
  url,
}) => (
  <StyledDisplayIcon
    background={background}
    className={className}
    size={size}
    round={round}
  >
    {!url && abbr && (
      <div className="display-icon__abbr">
        {abbr
          .split(/ |\.|_/g)
          .slice(0, 2)
          .map((l) => l.slice(0, 1))
          .join('')
          .toUpperCase()}
      </div>
    )}
    {url && (
      <Imgix
        className="display-icon__image"
        path={url}
        options={{
          height: size,
          width: size,
          fit: ImgixFitMode.Crop,
        }}
      />
    )}
  </StyledDisplayIcon>
)
