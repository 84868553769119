import React, { FunctionComponent, useEffect, useState } from 'react'
import { app } from 'firebase/app'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthedCollection } from '@humancollective/build-firebase'

import { UserMenu } from '../UserMenu'
import { IconDashboard, IconLogo, IconRightCarat } from '../../icons'
import { viewDashboardRoute } from '../../routes/dashboards'

const StyledSidebarLayout = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: white;
  align-items: stretch;

  @media (max-width: 720px) {
    flex-direction: column;
  }

  .app-layout {
    &__sidebar {
      border-right: 0.5px solid #e2e6ea;

      display: flex;
      flex-direction: column;
      min-width: 256px;

      &__logo {
        margin: 32px 40px;
        color: #11142d;
        width: 64px;
        height: 48px;
      }
      &__spacer {
        flex: 1;
      }
      &__section {
        padding: 32px 40px 0;
        &__heading {
          font-size: 12px;
          color: #b2b3bd;
          margin-bottom: 16px;
        }
        &__link {
          padding: 24px;
          margin: 0 -24px;
          border-radius: 12px;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.2px;
          cursor: pointer;
          color: #808191;
          transition: color ease-out 240ms, background-color ease-out 240ms;
          display: flex;
          align-items: center;
          text-decoration: none;

          &__icon {
            margin-right: 16px;
          }
          &__label {
            flex: 1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 12px;
            max-width: 160px;
          }
          &__carat {
            width: 8px;
          }

          &:hover {
            background: #f5f5f5;
          }
          &.is--selected {
            background: #072bff;
            color: white;
            &:hover {
              background: #0d2bd7;
            }
          }
        }
      }
      &__account {
        padding: 32px 40px;
      }
    }
    &__main {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
    }
    &__extension {
      flex: 1;
      border-left: 0.5px solid #e2e6ea;
      background-color: #f8f9fb;
    }
  }
`

export interface SidebarLayoutProps {
  Sidebar?: any
}

export const SidebarLayout: FunctionComponent<SidebarLayoutProps> = ({
  children,
}) => {
  const { dashboardId } = useParams<{ dashboardId?: string }>()
  const [dashboards, setDashboards] = useState<any[]>([])

  const dashboardPermissions = useAuthedCollection((uid) =>
    app('dashboard')
      .firestore()
      .collection('permissions')
      .doc(uid)
      .collection('dashboards')
  )

  const updateDashboards = async (ids: string[]) => {
    const dashboardPromises = ids.map((id) =>
      app('dashboard').firestore().collection('dashboards').doc(id).get()
    )
    const dashboardDocs = await Promise.all(dashboardPromises)
    const nextDashboards = dashboardDocs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    setDashboards(nextDashboards)
  }

  useEffect(() => {
    const dashboardIds = dashboardPermissions?.map(({ id }) => id) ?? []
    updateDashboards(dashboardIds)
  }, [dashboardPermissions])

  return (
    <StyledSidebarLayout>
      <div className="app-layout__sidebar">
        <IconLogo className="app-layout__sidebar__logo" />
        <div className="app-layout__sidebar__section">
          <h4 className="app-layout__sidebar__section__heading">Dashboards</h4>
          {dashboards.map(({ name = 'Untitled Dashboard', id }) => (
            <Link
              key={id}
              to={viewDashboardRoute({ dashboardId: id })}
              className={`app-layout__sidebar__section__link ${
                dashboardId === id ? 'is--selected' : ''
              }`}
            >
              <IconDashboard className="app-layout__sidebar__section__link__icon" />
              <div className="app-layout__sidebar__section__link__label">
                {name}
              </div>
              <IconRightCarat className="app-layout__sidebar__section__link__carat" />
            </Link>
          ))}
        </div>
        <div className="app-layout__sidebar__spacer" />
        <UserMenu className="app-layout__sidebar__account" />
      </div>
      <div className="app-layout__main">{children}</div>
    </StyledSidebarLayout>
  )
}
