import React, { useRef, useState } from 'react'
import { Button } from '@humancollective/build-ui'

export interface UploadFileProps {
  buttonLabel?: string
  onSelectFile: (
    selectedFile: File | undefined,
    setProgress: (progress: number) => void,
  ) => Promise<void>
}

export const UploadFile: React.FunctionComponent<UploadFileProps> = ({
  buttonLabel = 'Upload File',
  onSelectFile,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadProgress, setUploadProgress] = useState(0)

  const onClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    const selectedFile: File | undefined = files ? files[0] : undefined

    if (!selectedFile) {
      throw new Error('no file selected')
    }

    onSelectFile(selectedFile, (progress: number) =>
      setUploadProgress(progress),
    )
  }

  return (
    <div>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={fileInputRef}
        onChange={handleChange}
      />
      <Button size="small" appearance="outline" onClick={onClick}>
        {uploadProgress > 0 ? `Uploading ${uploadProgress}...` : buttonLabel}
      </Button>
    </div>
  )
}
