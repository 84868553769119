import styled from 'styled-components'
import {
  Card,
  rhythm,
  scaleFontSize,
  BASE_RADIUS,
} from '@humancollective/build-ui'

export const StyledRelationshipField = styled(Card)`
  margin: 0;

  .relationship-field {
    &__link {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      align-items: center;
      padding: ${rhythm(1 / 2)}px;
      &__image {
        margin-right: ${rhythm()}px;
        border-radius: ${BASE_RADIUS}px;
      }
      &__title {
        font-weight: 900;
      }
      &__subtitle {
        ${scaleFontSize(-1)};
        color: #757575;
      }
    }
    &__add-connection {
      display: flex;
      margin-top: ${rhythm()}px;
      &__select {
        flex: 1;
        margin-right: ${rhythm(1 / 2)}px;
      }
    }
  }
`
