import styled from 'styled-components'
import { rhythm } from '@humancollective/build-ui'

export const StyledAppCard = styled.div`
  flex: 1;
  display: flex;
  margin: 8px;

  .app-card {
    &__remove {
      height: ${rhythm(3 / 2)}px;
      width: ${rhythm(3 / 2)}px;
      background: red;
      position: absolute;
      border-radius: 50%;
      color: white;
      text-align: center;
      font-weight: 600;
      font-size: 25px;
      line-height: 32px;
      cursor: pointer;
    }
  }
`
