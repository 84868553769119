import styled from 'styled-components'
import { rhythm } from '@humancollective/build-ui'

export const EditorFormDivider = styled.hr`
  margin-top: ${rhythm(2)}px;
  margin-bottom: ${rhythm(2)}px;
  border: none;
  height: 1px;
  background: #e4e7ea;
`
