import React, { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import {
  useAuthedDocument,
  buildDocumentRef,
} from '@humancollective/build-firebase'

import { AuthContext } from '../../contexts/AuthContext'
import EditorTemplate from '../../templates/EditorTemplate'
import { viewDashboardRoute } from '../dashboards'
import { listCollectionRoute } from './router'

export interface EditDocumentRouteParams {
  dashboardId?: string
  collectionId: string
  docId: string
}

const EditDocument: React.FunctionComponent = () => {
  const { dashboardId, collectionId, docId } = useParams<{
    dashboardId?: string
    collectionId: string
    docId: string
  }>()
  const { getApp } = useContext(AuthContext)
  const collection = useAuthedDocument(() =>
    firebase.app('dashboard').firestore().collection('apps').doc(collectionId)
  )

  const app = useMemo(
    () => (collection ? getApp(collection) : undefined),
    [collection, getApp]
  )

  return app ? (
    <EditorTemplate
      breadcrumbs={[
        {
          label: 'Dashboard',
          to: dashboardId ? viewDashboardRoute({ dashboardId }) : '/',
        },
        {
          label: collection.label,
          to: listCollectionRoute({ dashboardId, collectionId }),
        },
        { label: 'Edit Document' },
      ]}
      formSchema={collection?.formSchema || []}
      getQueryRef={() =>
        buildDocumentRef(`${collection?.firebasePath}.${docId}`, app)
      }
      previewUrl={collection?.previewUrl}
    />
  ) : null
}

export default EditDocument
