import { useState, useEffect, useMemo } from 'react'
import { firestore } from 'firebase/app'

import { CollectionRefGetter, makeCollectionRef } from '../utils/makeRef'
import { useAuth } from './useAuth'

export const useAuthedCollectionRef = (
  collectionRef:
    | CollectionRefGetter
    | firestore.CollectionReference
    | firestore.Query,
  dependencies: any[] = [],
) => {
  const [reference, setReference] = useState<
    firestore.CollectionReference | firestore.Query
  >()

  const app = useMemo(() => makeCollectionRef(collectionRef).firestore.app, [
    collectionRef,
  ])
  const firebaseUser = useAuth(app)

  useEffect(() => {
    if (firebaseUser) {
      setReference(
        makeCollectionRef(collectionRef, firebaseUser.uid, dependencies),
      )
    }
  }, [firebaseUser, ...dependencies])

  return reference
}
