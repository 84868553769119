import React, { FunctionComponent } from 'react'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { firestore } from 'firebase/app'
import { useLoading } from '@humancollective/build-ui'

import {
  ListTemplate,
  SharedListTemplateProps,
  SearchableTemplate,
} from './index'

type CombinedProps = SharedListTemplateProps &
  SearchableTemplate & {
    getQueryRef: (
      firebaseUserId: string,
      dependencies?: any[] | undefined
    ) => firestore.CollectionReference | firestore.Query
  }

export const PostsListTemplate: FunctionComponent<CombinedProps> = ({
  getQueryRef,
  ...props
}) => {
  const data = useAuthedCollection(getQueryRef)
  useLoading(!!data)
  return data ? <ListTemplate data={data} {...props} /> : null
}
