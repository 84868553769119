import React, { FunctionComponent } from 'react'

interface IconProps {
  className?: string
}

export const IconLogo: FunctionComponent<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="222"
    viewBox="0 0 512 222"
    fill="currentColor"
    {...props}
  >
    <title>Field CMS</title>
    <path
      fillRule="nonzero"
      d="M235.6 74.152c38.32 0 61.007 27.03 61.007 54.366v.371c-.01 4.225-.235 18.42-5.314 23.634-3.494 3.587-7.957 5.21-13.387 4.867h-75.723c1.226 14.436 17.168 32.866 45.066 32.866 10.832 0 26.892-5.549 48.18-16.645v33.216c-10.423 5.222-36.837 14.451-54.924 14.451-41.08 0-75.723-29.487-75.723-73.717 0-46.38 34.642-73.41 70.817-73.41ZM512 19.42v198.19h-32.772v-16.199h-.306c-7.044 11.308-21.44 19.866-42.572 19.866-32.16 0-66.768-25.062-66.768-72.74 0-43.706 31.24-73.047 70.443-73.047 14.701 0 30.934 5.196 36.447 10.392V19.42H512ZM82.613 0c11.933 0 22.642 1.53 29.985 4.587l-6.731 29.36c-5.814-2.447-13.463-4.282-19.276-4.282-18.97 0-28.456 11.621-28.456 34.252V79.21h87.814v138.538h-36.41v-109.18H58.135v109.18H22.336v-109.18H0V79.21h22.336V61.47C22.336 20.796 47.12 0 82.613 0ZM350.73 19.42v198.327h-36.468V19.421h36.468Zm94.502 88.163c-22.052 0-39.204 17.42-39.204 41.566 0 24.45 17.152 41.566 39.204 41.566 15.62 0 26.033-7.947 31.24-15.893v-54.097c-4.288-5.196-15.008-13.142-31.24-13.142Zm-209.02-4.559c-15.021 0-30.043 11.98-33.11 27.951h58.25c0-1.229.306-2.15.306-3.379 0-11.671-10.117-24.572-25.445-24.572Z"
    />
  </svg>
)
