import styled from 'styled-components'
import { BASE_RADIUS, rhythm } from '@humancollective/build-ui'

export const StyledImageField = styled.div`
  display: flex;
  align-items: center;

  .switch-form-group {
    &__preview {
      border-radius: ${BASE_RADIUS}px;
      border: 1px solid #ced4db;
      background-color: #f8f9fb;
      cursor: pointer;
    }
    &__modal-preview {
      background-color: #f8f9fb;
      max-width: 70vw;
      max-height: 70vh;
    }
    &__controls {
      margin-left: ${rhythm(1 / 2)}px;
    }
  }
`
