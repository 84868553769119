import React, { FunctionComponent } from 'react'

import { SelectionMenuMode } from '../types'

interface MainModeProps {
  setMode: (mode: SelectionMenuMode) => void
}

const MainMode: FunctionComponent<MainModeProps> = ({ setMode }) => (
  <>
    <button onClick={() => document.execCommand('bold', false)}>
      <strong>B</strong>
    </button>
    <button onClick={() => document.execCommand('italic', false)}>
      <em>I</em>
    </button>
    <button onClick={() => document.execCommand('underline', false)}>
      <u>U</u>
    </button>
    <button onClick={() => setMode(SelectionMenuMode.AddLink)}>🔗</button>
  </>
)

export default MainMode
