import React from 'react'
import { Route } from 'react-router-dom'

import EditPage from './Edit'
import ListPage from './List'

const DashboardUserRoutes = () => (
  <>
    <Route exact path="/dashboard/users/:userId/">
      <EditPage />
    </Route>
    <Route exact path="/dashboard/users/">
      <ListPage />
    </Route>
  </>
)

export default DashboardUserRoutes
