import { firestore } from 'firebase/app'

export type DocumentRefGetter = (
  firebaseUserId: string,
  dependencies?: any[],
) => firestore.DocumentReference

export const makeDocumentRef = (
  documentRef: DocumentRefGetter | firestore.DocumentReference,
  uid: string = 'unused_uid',
  dependencies: any[] = [],
) =>
  typeof documentRef === 'function'
    ? documentRef(uid, dependencies)
    : documentRef

export type CollectionRefGetter = (
  firebaseUserId: string,
  dependencies?: any[],
) => firestore.CollectionReference | firestore.Query

export const makeCollectionRef = (
  collectionRef:
    | CollectionRefGetter
    | firestore.CollectionReference
    | firestore.Query,
  uid: string = 'unused_uid',
  dependencies: any[] = [],
) =>
  typeof collectionRef === 'function'
    ? collectionRef(uid, dependencies)
    : collectionRef
