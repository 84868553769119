import styled from 'styled-components'
import {
  INPUT_COLOR,
  palette,
  BASE_RADIUS,
  rhythm,
} from '@humancollective/build-ui'

export const StyledInputField = styled.div<{
  expanded: boolean
  focused: boolean
}>`
  // trick to maintain the bottom border when the input is not expanded
  box-shadow: inset 0 -1px #ced4db;

  .input-form-group {
    &__label {
      ${({ focused }) => (focused ? `color: ${palette.primary};` : '')}
      ${({ expanded }) =>
        expanded
          ? `
            transform: translateY(0);
            transition: color 250ms ease-out, transform 200ms ease-out;
          `
          : `
            transform: translateY(24px);
            transition: color 250ms ease-out, transform 200ms ease-out;
          `}
    }
    &__input {
      display: block;
      width: 100%;
      background-color: transparent;
      padding: ${rhythm(1 / 6)}px 0;
      border: none;
      border-bottom: 1px solid #ced4db;
      color: ${INPUT_COLOR};
      transition: border-color 250ms ease-out;
      resize: vertical;
      opacity: ${({ expanded }) => (expanded ? 1 : 0)};
      &[type='color'] {
        width: ${rhythm(2)}px;
        height: ${rhythm(7 / 3)}px;
        &::-webkit-color-swatch {
          border-radius: ${BASE_RADIUS}px;
        }
      }
      &:focus {
        outline: none;
        border-bottom: 1px solid ${palette.primary};
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }
`
