import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Grid } from '@humancollective/build-grid'
import { palette, rhythm, scaleFontSize } from '@humancollective/build-ui'

const StyledMetricTempalateRow = styled.div`
  display: block;
  padding-top: ${rhythm(1 / 2)}px;
  padding-bottom: ${rhythm(1 / 2)}px;
  border-bottom: 1px solid #f5f5f5;

  .metric-template-row {
    display: flex;
    align-items: center;
    &__subtitle {
      ${scaleFontSize(-1)};
      color: #9198ab;
    }
    &__icon {
      margin-right: ${rhythm(1 / 2)}px;
    }
  }

  transition: background-color 400ms ease;
  cursor: pointer;

  .metric-template-row__label,
  .metric-template-row__subtitle {
    transition: color 400ms ease;
  }

  &:hover {
    background-color: #fafcff;
    .metric-template-row__label,
    .metric-template-row__subtitle {
      color: ${palette.primary};
    }
  }
`

interface MetricTemplateRowProps {
  label: string
  value: string
}

export const MetricTemplateRow: FunctionComponent<MetricTemplateRowProps> = ({
  label,
  value,
}) => (
  <StyledMetricTempalateRow>
    <Grid.Container>
      <Grid.Inner withMargins>
        <div className="metric-template-row">
          <div>
            <h5 className="metric-template-row__label">{label}</h5>
            <div className="metric-template-row__value">{value}</div>
          </div>
        </div>
      </Grid.Inner>
    </Grid.Container>
  </StyledMetricTempalateRow>
)
