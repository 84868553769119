import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import ListAppsPage from './List'
import EditAppPage, { EditAppRouteParams } from './Edit'

export const listAppsRoute = () => `/a/`

export const editAppRoute = ({ dashboardId, appId }: EditAppRouteParams) =>
  dashboardId ? `/d/${dashboardId}/a/${appId}/edit` : `/a/${appId}/edit`

export const addAppRoute = ({ dashboardId }: EditAppRouteParams) =>
  `/d/${dashboardId}/a/add`

const AppsRouter: FunctionComponent = () => (
  <Switch>
    <Route exact path={listAppsRoute()}>
      <ListAppsPage />
    </Route>
    <Route
      exact
      path={[
        editAppRoute({
          appId: ':appId',
        }),
        editAppRoute({
          dashboardId: ':dashboardId',
          appId: ':appId',
        }),
      ]}
    >
      <EditAppPage />
    </Route>
  </Switch>
)

export default AppsRouter
