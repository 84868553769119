import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Grid } from '@humancollective/build-grid'

export interface SharedMetricTemplateProps {
  renderItem: (item: any) => React.ReactNode
}

export interface SearchableTemplate {
  searchKeys?: string[]
}

export interface TemplateData {
  data?: any[] | null
}

const StyledMetricTemplate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;

  .metric-template {
    &__contents {
      flex: 1;
      border-top: 1px solid #f5f5f5;
    }
  }
`

export const MetricTemplate: FunctionComponent<SharedMetricTemplateProps &
  SearchableTemplate &
  TemplateData> = ({ renderItem, data = [] }) => {
  return data ? (
    <StyledMetricTemplate>
      <Grid.Container>
        <Grid.Inner withMargins>
          <div className="metric-template__contents">
            {data.map(renderItem)}
          </div>
        </Grid.Inner>
      </Grid.Container>
    </StyledMetricTemplate>
  ) : null
}
