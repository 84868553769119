import firebase, { firestore } from 'firebase/app'

// Builds a ref from a definition that can be stored in a JSON object.

export const buildCollectionRef = (
  firebasePath: string,
  order?: { path: string; direction?: 'asc' | 'desc' },
  app?: firebase.app.App,
) => {
  const firebaseApp = app || firebase.app()
  let ref = firebaseApp.firestore() as unknown
  const parts = firebasePath.split('.')
  parts.forEach((part, index) => {
    const type = index % 2 === 0 ? 'collection' : 'doc'
    ref = (ref as any)[type](part)
  })
  if (order) {
    ref = (ref as firestore.CollectionReference | firestore.Query).orderBy(
      order.path,
      order.direction,
    )
  }
  return ref as firestore.CollectionReference | firestore.Query
}

export const buildDocumentRef = (path: string, app?: firebase.app.App) => {
  const firebaseApp = app || firebase.app()
  let ref = firebaseApp.firestore() as unknown
  const parts = path.split('.')
  parts.forEach((part, index) => {
    const type = index % 2 === 0 ? 'collection' : 'doc'
    ref = (ref as any)[type](part)
  })
  return ref as firebase.firestore.DocumentReference
}
