import React, { useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import firebase from 'firebase/app'
import { useAuthedDocument } from '@humancollective/build-firebase'
import styled from 'styled-components'
import { Grid } from '@humancollective/build-grid'
import { Button, rhythm, useLoading } from '@humancollective/build-ui'

import { AuthContext } from '../../contexts/AuthContext'
import { listFormResponsesRoute } from '.'

export interface ViewFormResponseRouteParams {
  dashboardId?: string
  accountId: string
  responseId: string
}

const StyledViewFormResponse = styled.div`
  background: white;
  flex: 1;
  padding-top: ${rhythm()}px;

  table {
    margin-top: ${rhythm()}px;
    width: 100%;

    td {
      padding-top: ${rhythm(1 / 2)}px;
      padding-bottom: ${rhythm(1 / 2) - 1}px;
      border-bottom: 1px solid #f5f5f5;
    }

    td:nth-child(1) {
      font-weight: 900;
    }
  }
`

const ViewFormResponse: React.FunctionComponent = () => {
  const { dashboardId, accountId, responseId } = useParams<ViewFormResponseRouteParams>()
  const { getApp } = useContext(AuthContext)

  const app = getApp({
    firebaseConfig: {
      apiKey: 'AIzaSyBRxwelt7v1OstYbuc6YXwhyPj0JNd0AhI',
      authDomain: 'human-tools-production.firebaseapp.com',
      databaseURL: 'https://human-tools-production.firebaseio.com',
      projectId: 'human-tools-production',
      storageBucket: 'human-tools-production.appspot.com',
      messagingSenderId: '572572490447',
      appId: '1:572572490447:web:5da4c629344f50b8b975ba',
    },
    tokenUrl: 'https://hmn.to/auth/',
  }) as firebase.app.App

  const spec = useAuthedDocument(() =>
    app
      .firestore()
      .collection('form-relays')
      .doc(accountId)
  )
  const data = useAuthedDocument(() =>
    app
      .firestore()
      .collection('form-responses')
      .doc(responseId)
  )

  useLoading(!!data, !!spec)

  return data ? (
    <StyledViewFormResponse>
      <Grid.Container>
        <Grid.Inner withMargins>
          <Button
            tagName={Link}
            appearance="outline"
            to={listFormResponsesRoute({ dashboardId, accountId })}
          >
            Back
          </Button>
          <table>
            <tbody>
              {spec.data.map(({ label, name }: any) => (
                <tr key={name}>
                  <td>{label}</td>
                  <td>{data.responseData[name]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid.Inner>
      </Grid.Container>
    </StyledViewFormResponse>
  ) : null
}

export default ViewFormResponse
