import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import styled, { ThemeProvider } from 'styled-components'
// import * as Sentry from '@sentry/browser'
import { Auth0Provider } from '@auth0/auth0-react'
import { gridSettingsFromColumnsAndUnits } from '@humancollective/build-grid'
import {
  UsersContextProvider,
  QueriesContextProvider,
} from '@humancollective/build-firebase'
import {
  UIContextProvider,
  HelmetFonts,
  HelmetViewport,
  GlobalCSS,
  PrismCSS,
} from '@humancollective/build-ui'

import Router from './routes'
import { AuthContextProvider } from './contexts/AuthContext'
import { RecaptchaContextProvider } from './contexts/RecaptchaContext'

// Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN })

firebase.initializeApp(
  {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  },
  'dashboard'
)

const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const App = () => (
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
  >
    <RecaptchaContextProvider>
      <ThemeProvider
        theme={{
          grid: gridSettingsFromColumnsAndUnits({
            marginUnits: 1,
          }),
        }}
      >
        <UIContextProvider>
          <AuthContextProvider>
            <UsersContextProvider>
              <QueriesContextProvider>
                <BrowserRouter>
                  <HelmetFonts />
                  <HelmetViewport />
                  <GlobalCSS />
                  <PrismCSS />
                  <PageContainer>
                    <Router />
                  </PageContainer>
                </BrowserRouter>
              </QueriesContextProvider>
            </UsersContextProvider>
          </AuthContextProvider>
        </UIContextProvider>
      </ThemeProvider>
    </RecaptchaContextProvider>
  </Auth0Provider>
)

export default App
