import React, { FunctionComponent } from 'react'

interface IconProps {
  className?: string
}

export const IconEllipsis: FunctionComponent<IconProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="4"
    viewBox="0 0 16 4"
    fill="currentColor"
    {...props}
  >
    <g fillRule="nonzero">
      <circle cx="2" cy="2" r="2" />
      <circle cx="8" cy="2" r="2" />
      <circle cx="14" cy="2" r="2" />
    </g>
  </svg>
)
