import React, { FunctionComponent, useState, useEffect } from 'react'
import { Label } from '@humancollective/build-ui'

import { StyledInputField } from './style'
import { UrlPreview } from './UrlPreview'

const Tag: FunctionComponent<any> = ({
  tagName = 'input',
  children,
  name,
  ...props
}) => React.createElement(tagName, props, children)

export interface InputFieldProps extends React.HTMLProps<any> {
  multiline?: boolean
  StatusComponent?: FunctionComponent<{ focused: boolean }>
  onChangeValue: (value: string | number) => void | Promise<void>
  focused?: boolean
  setFocused?: (focused: boolean) => void | Promise<void>
}

const sanitize = (value: string, type?: string) => {
  if (type === 'number' && value.length > 0) {
    return Number(value)
  }
  return value
}

export const InputField: FunctionComponent<InputFieldProps> = ({
  name,
  value,
  onChangeValue,
  setFocused,
  label,
  className,
  multiline,
  type,
  ...rest
}) => {
  const [internalFocused, setInternalFocused] = useState(false)

  useEffect(() => {
    if (setFocused) {
      setFocused(internalFocused)
    }
  }, [internalFocused, setFocused])

  return (
    <StyledInputField
      className={className}
      expanded={!!value || internalFocused}
      focused={internalFocused}
    >
      {label && <Label className="input-form-group__label">{label}</Label>}
      <Tag
        tagName={multiline ? 'textarea' : 'input'}
        className="input-form-group__input"
        value={value}
        type={type}
        onChange={(e: any) => onChangeValue(sanitize(e.target.value, type))}
        onFocus={() => setInternalFocused(true)}
        onBlur={() => setInternalFocused(false)}
        {...rest}
      />
      {type === 'url' && (
        <UrlPreview title={`${name}-preview`} value={value as string} />
      )}
    </StyledInputField>
  )
}
