import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { palette } from '../../theme'

const StyledProgress = styled.div<{ value: number }>`
  width: 100%;
  height: 4px;
  background: #dde8f7;

  .progress {
    &__bar {
      transition: width 200ms ease;
      width: ${({ value = 0 }) => value}%;
      height: 100%;
      background: ${palette.primary};
    }
  }
`

interface ProgressProps {
  value: number
  className?: string
}

export const Progress: FunctionComponent<ProgressProps> = ({
  value,
  className,
}) => (
  <StyledProgress className={className} value={value}>
    <div className="progress__bar" />
  </StyledProgress>
)
