import React, { useState } from 'react'
import find from 'lodash/find'
import Select from 'react-select'
import styled from 'styled-components'
import set from 'lodash/set'
import { Button, rhythm, BASE_RADIUS } from '@humancollective/build-ui'

const StyledControlAdd = styled.div`
  display: flex;
  flex: 1;
  .dynamic-form-list-control-add {
    &__select {
      flex: 1;
    }
    &__button {
      margin-left: ${rhythm(1 / 2)}px;
    }
  }
`

interface ControlAddProps {
  typeKey: string
  types: any[]
  onAdd: (type: string) => void
}

export const ControlAdd: React.FunctionComponent<ControlAddProps> = ({
  typeKey,
  types,
  onAdd,
}) => {
  const [typeValue, setTypeValue] = useState('none')

  return (
    <StyledControlAdd>
      <Select
        className="dynamic-form-list-control-add__select"
        options={types.map(t => ({ label: t.label, value: t.id }))}
        onChange={(v: any) => {
          setTypeValue(v.value)
        }}
        styles={{
          control: styles => ({
            ...styles,
            borderColor: '#ced4db',
            minHeight: rhythm(5 / 3),
            borderRadius: BASE_RADIUS,
          }),
        }}
      />
      <Button
        className="dynamic-form-list-control-add__button"
        appearance="outline"
        disabled={typeValue === 'none'}
        onClick={() => {
          const defaults = find(types, { id: typeValue })?.defaults || {}
          onAdd(set({ ...defaults }, typeKey, typeValue))
          setTypeValue('none')
        }}
      >
        Add
      </Button>
    </StyledControlAdd>
  )
}
