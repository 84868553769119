import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'

import DashboardPage, { ViewDashboardRouteParams } from './View'
import EditDashboardPage, { EditDashboardRouteParams } from './Edit'
import ListDashboardsPage from './List'

export const viewDashboardRoute = ({ dashboardId }: ViewDashboardRouteParams) =>
  `/d/${dashboardId}/`

export const editDashboardRoute = ({ dashboardId }: EditDashboardRouteParams) =>
  `/d/${dashboardId}/edit`

export const listDashboardsRoute = () => `/d/`

const ProjectRouter: FunctionComponent = () => (
  <Switch>
    <Route exact path={viewDashboardRoute({ dashboardId: ':dashboardId' })}>
      <DashboardPage />
    </Route>
    <Route exact path={editDashboardRoute({ dashboardId: ':dashboardId' })}>
      <EditDashboardPage />
    </Route>
    <Route exact path={listDashboardsRoute()}>
      <ListDashboardsPage />
    </Route>
  </Switch>
)

export default ProjectRouter
