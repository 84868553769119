import React, { FunctionComponent } from 'react'

import { EditorSyncStatus } from '../../types'
import { StyledSyncStatus } from './style'

interface SyncStatusProps {
  status: EditorSyncStatus
  show?: boolean
}

export const SyncStatus: FunctionComponent<SyncStatusProps> = ({
  status,
  show = true,
}) => (
  <StyledSyncStatus status={status} show={show}>
    {status}
  </StyledSyncStatus>
)
