import styled from 'styled-components'
import { rhythm } from '@humancollective/build-ui'

const StyledEditorTemplate = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .editor-template {
    &__columns {
      display: flex;
      width: 100%;
    }
    &__preview-container {
      flex: 1;
    }
    &__preview {
      border: none;
      height: 100%;
      width: 100%;
      border-left: 0.5px solid #e2e6ea;
    }
    &__fields {
      flex: 1;
      max-width: 640px;
      margin: 0 auto;
      padding: ${rhythm(2)}px;
      overflow-y: auto;
    }
    &__form {
      background-color: white;
      display: flex;
      flex: 1;
      flex-shrink: 1;
      flex-direction: column;
      overflow: hidden;
    }
    &__contents-container {
      display: flex;
      flex: 1;
      overflow: auto;
    }
    &__contents {
      display: flex;
      flex-wrap: wrap;
    }
    &__controls {
      border-top: 1px solid #e5e7ea;
      background-color: #f8f9fb;
      display: flex;
      justify-content: center;
      padding-top: ${rhythm(2 / 3)}px;
      padding-bottom: ${rhythm(2 / 3)}px;
    }
  }

  .editor-form {
    &__description {
      max-width: 540px;
    }
    &__input,
    &__image-uploader {
      margin-bottom: 24px;
    }
    &__submit,
    &__remove {
      margin-top: 24px;
    }
    &__submit {
      margin-right: 16px;
    }
    &__fields {
      flex-shrink: 1;
      flex: 1;
      overflow-x: hidden;
    }
  }
`

export default StyledEditorTemplate
