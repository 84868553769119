import React from 'react'
import { Route } from 'react-router-dom'

import AuthRoutes from './auth'
import CollectionRouter from './collection'
import HomePage from './home'
import DashboardUserRoutes from './user'
import DashboardsRouter, { viewDashboardRoute } from './dashboards'
import AppsRouter from './apps'
import WelcomeRouter from './welcome'
import RequestRouter from './request'
import PostsRouter from './posts'
import MetricRouter from './metric'
import FormsRouter from './forms'
import { SidebarLayout } from '../components/SidebarLayout'

const Router = () => (
  <>
    <Route exact path="/">
      <HomePage />
    </Route>
    <Route path={viewDashboardRoute({ dashboardId: ':dashboardId' })}>
      <SidebarLayout>
        <MetricRouter />
        <DashboardsRouter />
        <CollectionRouter />
        <PostsRouter />
        <RequestRouter />
        <WelcomeRouter />
        <DashboardUserRoutes />
        <FormsRouter />
      </SidebarLayout>
    </Route>
    <AppsRouter />
    <AuthRoutes />
  </>
)

export default Router
