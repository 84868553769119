import { useState, useEffect } from 'react'
import firebase, { auth } from 'firebase/app'
import 'firebase/auth'

export const useAuth = (app?: firebase.app.App) => {
  const [value, setValue] = useState<firebase.User | null | undefined>(
    undefined,
  )
  useEffect(() => auth(app).onAuthStateChanged(u => setValue(u)), [app])
  return value
}
