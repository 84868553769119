import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

import { rhythm } from '../../style/typography'
import { BASE_RADIUS, palette } from '../../theme'

const StyledPagination = styled.div`
  ul {
    display: flex;
    list-style: none;
    margin: ${rhythm(1 / 2)}px;
    padding: 0;
  }

  li {
    cursor: pointer;
    border-radius: ${BASE_RADIUS}px;
    transition: color 400ms ease, background-color 400ms ease;
    margin: 0 ${rhythm(1 / 3)}px;
    &.is--selected {
      color: white;
      background-color: ${palette.primary};
      &:hover {
        background-color: ${palette.primaryHover};
      }
    }
    &:hover {
      background-color: #dfe5f8;
    }
  }

  a {
    color: #242424;
  }
`

interface PaginationProps {
  count: number
  index: number
  onChange: (index: number) => void
}

export const Pagination: FunctionComponent<PaginationProps> = ({
  count,
  index,
  onChange,
}) => {
  return (
    <StyledPagination>
      <ReactPaginate
        pageCount={count}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        initialPage={index - 1}
        onPageChange={e => onChange(e.selected + 1)}
        previousLabel="←"
        nextLabel="→"
      />
    </StyledPagination>
  )
}
