import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Grid } from '@humancollective/build-grid'

const StyledGalleryTemplate = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f7f9fb;

  .gallery-template {
    &__meta {
      flex: 0;
    }
    &__body {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
    &__search-container {
      display: flex;
      align-items: center;
    }
    &__search-icon {
      margin-right: 8px;
    }
    &__pagination-container {
      border-top: 1px solid #e4e4e4;
      display: flex;
      justify-content: center;
    }
  }
`

export interface GalleryTemplateProps {
  data?: any[]
  renderItem: (item: any) => React.ReactNode
}

export const GalleryTemplate: FunctionComponent<GalleryTemplateProps> = ({
  renderItem,
  data = [],
}) => (
  <StyledGalleryTemplate>
    <Grid.Container>
      <Grid.Inner withMargins>
        <div className="gallery-template__body">{data.map(renderItem)}</div>
      </Grid.Inner>
    </Grid.Container>
  </StyledGalleryTemplate>
)
