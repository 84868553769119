import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Grid } from '@humancollective/build-grid'
import { palette, rhythm, scaleFontSize } from '@humancollective/build-ui'

import { DisplayIcon } from '../../components/display/Icon'

const StyledListTempalateRow = styled.div`
  display: block;
  padding-top: ${rhythm(1 / 2)}px;
  padding-bottom: ${rhythm(1 / 2)}px;
  border-bottom: 1px solid #f5f5f5;

  .list-template-row {
    display: flex;
    align-items: center;
    &__subtitle {
      ${scaleFontSize(-1)};
      color: #9198ab;
    }
    &__icon {
      margin-right: ${rhythm(1 / 2)}px;
    }
  }

  transition: background-color 400ms ease;
  cursor: pointer;

  .list-template-row__label,
  .list-template-row__subtitle {
    transition: color 400ms ease;
  }

  &:hover {
    background-color: #fafcff;
    .list-template-row__label,
    .list-template-row__subtitle {
      color: ${palette.primary};
    }
  }
`

interface ListTemplateRowProps {
  to: string
  label: string
  subtitle?: string
  modified?: Date
  icon?: {
    background?: string
    abbr?: string
    url?: string
  }
}

export const ListTemplateRow: FunctionComponent<ListTemplateRowProps> = ({
  to,
  label,
  subtitle,
  icon,
}) => {
  const history = useHistory()
  return (
    <StyledListTempalateRow onClick={() => history.push(to)}>
      <Grid.Container>
        <Grid.Inner withMargins>
          <div className="list-template-row">
            {icon && (
              <DisplayIcon className="list-template-row__icon" {...icon} />
            )}
            <div>
              <h5 className="list-template-row__label">{label}</h5>
              {!!subtitle && (
                <div className="list-template-row__subtitle">{subtitle}</div>
              )}
            </div>
          </div>
        </Grid.Inner>
      </Grid.Container>
    </StyledListTempalateRow>
  )
}
