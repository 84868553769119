import styled from 'styled-components'

import { palette, BASE_RADIUS } from '../../theme'
import { rhythm, scaleFontSize } from '../../style/typography'

export type ButtonAppearance = 'primary' | 'outline' | 'ghost'
export type ButtonSize = 'small' | 'normal'

const appearances: { [appearance: string]: (color: string) => string } = {
  primary: (color) => `
    color: white;
    background-color: ${color};
    border-color: transparent;
  `,
  outline: (color) => `
    color: ${color};
    background-color: transparent;
    border-color: ${color};
  `,
  ghost: (color) => `
    color: ${color};
    background-color: transparent;
    border-color: transparent;
  `,
}

const sizes: { [size: string]: string } = {
  small: `
    ${scaleFontSize(-3)};
    line-height: ${rhythm(1 / 2)}px;
    padding: ${rhythm(1 / 3)}px ${rhythm(1 / 2)}px;
  `,
  normal: `
    ${scaleFontSize(-2)};
    line-height: ${rhythm(1 / 2)}px;
    padding: ${rhythm(1 / 2)}px ${rhythm()}px;
  `,
  large: `
    ${scaleFontSize(-1)};
    line-height: ${rhythm(1 / 2)}px;
    padding: ${rhythm(2 / 3)}px ${rhythm()}px;
  `,
}

export interface ButtonProps {
  tagName?: string
  secondary?: boolean
  appearance?: ButtonAppearance
  size?: ButtonSize
  color?: string
  disabled?: boolean
}

const getColor = ({ appearance = 'primary', disabled, color }: ButtonProps) =>
  disabled
    ? appearances[appearance](color || '#a2abbe')
    : appearances[appearance](color || palette.primary)

const getHoverColor = ({
  appearance = 'primary',
  disabled,
  color,
}: ButtonProps) =>
  disabled
    ? appearances[appearance](color || '#a2abbe')
    : appearances[appearance](color || palette.primaryHover)

export const Button = styled.button<ButtonProps>`
  // layout
  display: inline-block;

  // color
  ${getColor};
  &:hover {
    ${getHoverColor};
  }

  // sizing
  ${({ size = 'normal' }) => sizes[size]};
  border-width: 2px;

  // styling
  border-radius: ${BASE_RADIUS}px;
  border-style: solid;

  // typography
  text-align: center;
  text-decoration: none;
  letter-spacing: 0.2px;
  font-weight: 600;

  // interaction
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  transition: background-color 250ms ease;
  user-select: none;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #cae2ff;
  }
`
