import React, { FunctionComponent } from 'react'
import { firestore } from 'firebase/app'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { Button, rhythm } from '@humancollective/build-ui'

import { GroupField } from '../GroupField'
import { ListFieldType } from '.'

const RowLabel: FunctionComponent<{ label: any; onRemove: () => void }> = ({
  label,
  onRemove,
}) => (
  <div className="dynamic-form-list__item-label">
    <div className="dynamic-form-list__item-label__name">{label}</div>
    <Button onClick={onRemove} size="small" appearance="ghost">
      Remove
    </Button>
  </div>
)

interface ListFieldRowProps {
  name: string
  index: number
  spec: ListFieldType | null
  onChangeValue: (value: any) => void | Promise<void>
  onRemove: () => void
  values: any
  documentRef: firestore.DocumentReference
  value: any
  getItemLabel?: (
    spec: ListFieldType | null,
    value: any
  ) => string | React.ReactNode
  draggableId: string
}

const StyledListFieldRow = styled.div`
  display: flex;

  .list-field-row {
    &__drag-handle {
      width: ${rhythm() - 1}px;
      border-left: 1px solid #e3e8ef;
      &.is--dragging {
        border-color: #0771ff;
      }
    }
    &__field {
      margin-bottom: ${rhythm(1 / 2)}px;
      flex: 1;
    }
  }
`

export const ListFieldRow: FunctionComponent<ListFieldRowProps> = ({
  index,
  spec,
  onChangeValue,
  onRemove,
  values,
  documentRef,
  value,
  getItemLabel,
  draggableId,
}) => {
  const itemLabel = getItemLabel ? getItemLabel(spec, value) : spec?.label
  return (
    <Draggable draggableId={draggableId} index={index}>
      {(draggableProvided, draggableSnapshot) => (
        <StyledListFieldRow
          ref={draggableProvided.innerRef}
          style={draggableProvided.draggableProps.style}
          {...draggableProvided.draggableProps}
        >
          <div
            className={`list-field-row__drag-handle ${
              draggableSnapshot.isDragging ? 'is--dragging' : ''
            }`}
            {...draggableProvided.dragHandleProps}
          />
          <GroupField
            className="list-field-row__field"
            values={values}
            documentRef={documentRef}
            fields={spec?.fields || []}
            onChangeValue={onChangeValue}
            value={value}
            label={<RowLabel label={itemLabel} onRemove={onRemove} />}
          />
        </StyledListFieldRow>
      )}
    </Draggable>
  )
}
