import React, { useContext, useMemo } from 'react'
import firebase from 'firebase/app'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@humancollective/build-ui'
import {
  useAuthedDocument,
  buildCollectionRef,
} from '@humancollective/build-firebase'

import { ListTemplateRow } from '../../templates/ListTemplate/ListTemplateRow'
import { PostsListTemplate } from '../../templates/ListTemplate/PostsList'
import { AuthContext } from '../../contexts/AuthContext'
import { editPostRoute } from './router'

export interface ListPostsRouteParams {
  dashboardId?: string
  collectionId: string
}

const ListDocument: React.FunctionComponent = () => {
  const { dashboardId, collectionId } = useParams<ListPostsRouteParams>()
  const history = useHistory()

  const { getApp } = useContext(AuthContext)
  const collection = useAuthedDocument(
    firebase
      .app('dashboard')
      .firestore()
      .collection('apps')
      .doc(collectionId)
  )

  const app = useMemo(() => (collection ? getApp(collection) : undefined), [
    collection,
    getApp,
  ])

  const onAddItem = async () => {
    const docRef = await firebase
      .app(app?.name)
      .firestore()
      .collection(collection?.firebasePath || 'none')
      .add({})
    history.push(editPostRoute({ dashboardId, collectionId, docId: docRef.id }))
  }

  return app ? (
    <PostsListTemplate
      getQueryRef={() =>
        buildCollectionRef(
          collection?.firebasePath || '',
          collection.order,
          app
        )
      }
      breadcrumbs={[
        { label: 'Dashboard', to: '/' },
        { label: collection.label },
      ]}
      searchKeys={['title']}
      controls={
        <>
          <Button onClick={onAddItem} appearance="primary" size="small">
            New
          </Button>
        </>
      }
      renderItem={(post: any) => (
        <ListTemplateRow
          key={post.id}
          to={editPostRoute({
            dashboardId,
            collectionId,
            docId: post.id,
          })}
          label={post.name || post.id}
          modified={post._modified?.toDate()}
        />
      )}
    />
  ) : null
}

export default ListDocument
