import styled from 'styled-components'
import { BASE_RADIUS, INPUT_COLOR, palette } from '@humancollective/build-ui'
import 'react-datetime/css/react-datetime.css'

export const StyledDateTimeField = styled.div`
  .rdtPicker {
    border: 1px solid #ced4db;
    box-shadow: none;
    border-radius: ${BASE_RADIUS}px;
  }
  .rdtPicker td.rdtDay:hover,
  .rdtPicker td.rdtHour:hover,
  .rdtPicker td.rdtMinute:hover,
  .rdtPicker td.rdtSecond:hover,
  .rdtPicker .rdtTimeToggle:hover,
  .rdtPicker thead tr:first-child th:hover,
  td.rdtMonth:hover,
  td.rdtYear:hover,
  .rdtCounter .rdtBtn:hover {
    border-radius: ${BASE_RADIUS}px;
    background-color: #fbfcff;
  }
  .rdtPicker td.rdtActive,
  .rdtPicker td.rdtActive:hover {
    background-color: ${palette.primary};
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    border-radius: ${BASE_RADIUS}px;
  }
  .rdtPicker td.rdtToday:before {
    border: 2px solid ${palette.primary};
    height: 24px;
    width: 24px;
    border-radius: 50%;
    top: 0;
    left: 2px;
  }
`

export const InputFormGroup = styled.div<{
  expanded: boolean
  focused: boolean
}>`
  // trick to maintain the bottom border when the input is not expanded
  box-shadow: inset 0 -1px #ced4db;

  .input-form-group {
    &__label {
      ${({ focused }) => (focused ? `color: ${palette.primary};` : '')}
      ${({ expanded }) =>
        expanded
          ? `
            transform: translateY(0);
            transition: color 250ms ease-out, transform 200ms ease-out;
          `
          : `
            transform: translateY(24px);
            transition: color 250ms ease-out, transform 200ms ease-out;
          `}
    }
    &__input {
      display: block;
      width: 100%;
      padding: 8px 0;
      border: none;
      background: transparent;
      border-bottom: 1px solid #ced4db;
      color: ${INPUT_COLOR};
      transition: border-color 250ms ease-out;
      opacity: ${({ expanded }) => (expanded ? 1 : 0)};
      &:focus {
        outline: none;
        border-bottom: 1px solid ${palette.primary};
      }
      &:disabled {
        opacity: 0.4;
        border-bottom-style: dotted;
      }
    }
  }
`
