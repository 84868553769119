import React from 'react'
import { app } from 'firebase/app'
import { useHistory } from 'react-router-dom'
import { Card } from '@humancollective/build-ui'

import { CollectionGalleryTemplate } from '../../templates/GalleryTemplate/CollectionGallery'

const ListDashboardUsers: React.FunctionComponent = () => {
  const history = useHistory()

  return (
    <CollectionGalleryTemplate
      getQueryRef={() => app('dashboard').firestore().collection('users')}
      renderItem={(user: any) => (
        <Card
          key={user.id}
          onClick={() => history.push(`/dashboard/users/${user.id}`)}
        >
          {user.name || user.id}
        </Card>
      )}
    />
  )
}

export default ListDashboardUsers
