import React, { useContext, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import firebase from 'firebase/app'
import { getDisplayValues, ListRow, Button } from '@humancollective/build-ui'
import {
  useAuthedDocument,
  buildCollectionRef,
} from '@humancollective/build-firebase'

import { AuthContext } from '../../contexts/AuthContext'
import { CollectionListTemplate } from '../../templates/ListTemplate/CollectionList'
import { editDocumentRoute } from './router'
import { viewDashboardRoute } from '../dashboards'

export interface ListCollectionRouteParams {
  dashboardId?: string
  collectionId: string
}

const CollectionList: React.FunctionComponent = () => {
  const history = useHistory()
  const { dashboardId, collectionId } = useParams<ListCollectionRouteParams>()
  const { getApp } = useContext(AuthContext)
  const collection = useAuthedDocument(
    firebase.app('dashboard').firestore().collection('apps').doc(collectionId)
  )
  const app = useMemo(
    () => (collection ? getApp(collection) : undefined),
    [collection, getApp]
  )

  const addItem = async () => {
    const docRef = await firebase
      .app('dashboard')
      .firestore()
      .collection('drafts')
      .doc(app?.name || 'none')
      .collection(collection?.firebasePath || 'none')
      .add({})
    history.push(
      editDocumentRoute({
        dashboardId,
        collectionId,
        docId: docRef.id,
      })
    )
  }

  return app ? (
    <CollectionListTemplate
      breadcrumbs={[
        {
          label: 'Dashboard',
          to: dashboardId ? viewDashboardRoute({ dashboardId }) : '/',
        },
        { label: collection.label },
      ]}
      getQueryRef={() =>
        buildCollectionRef(
          collection?.firebasePath || '',
          collection.order,
          app
        )
      }
      getDraftQueryRef={() =>
        firebase
          .app('dashboard')
          .firestore()
          .collection('drafts')
          .doc(app?.name || 'none')
          .collection(collection?.firebasePath || 'none')
      }
      controls={<Button onClick={addItem}>Add</Button>}
      searchKeys={collection?.searchKeys || []}
      renderItem={(doc: any) => {
        const display = getDisplayValues(doc, collection?.display)
        return (
          <ListRow
            key={doc.id}
            icon={display.icon}
            label={display.title}
            subtitle={display.subtitle}
            // statusLabel={draft ? 'CHANGED' : 'PUBLISHED'}
            // statusColor={draft ? '#f39c12' : '#9198ab'}
            onClick={() =>
              history.push(
                editDocumentRoute({
                  dashboardId,
                  collectionId,
                  docId: doc.id,
                })
              )
            }
          />
        )
      }}
    />
  ) : null
}

export default CollectionList
