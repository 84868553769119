import React, { useMemo } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import findIndex from 'lodash/findIndex'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { Card, rhythm, EmptyState } from '@humancollective/build-ui'
import { v4 as uuid } from 'uuid'

import { EditorFormField, EditorFormFieldProps } from '../../types'
import { ControlAdd } from './ControlAdd'
import { ListFieldRow } from './Row'

const StyledListField = styled(Card)`
  .dynamic-form-list {
    &__controls {
      display: flex;
      align-items: flex-end;
      margin-bottom: ${rhythm(2 / 3)}px;
    }
    &__button {
      margin-left: ${rhythm()}px;
    }
    &__item-label {
      display: flex;
      align-items: center;
      width: 100%;
      padding: ${rhythm(1 / 2)}px 0;

      &__name {
        flex: 1;
      }
    }
  }
`

export interface ListFieldType {
  id: string
  label: string
  defaults?: any
  fields: EditorFormField[]
}

export interface ListFieldProps {
  name: string
  label: string
  types: ListFieldType[]
  typeKey?: string
  getItemLabel?: (
    spec: ListFieldType | null,
    value: any
  ) => string | React.ReactNode
  oneOfEach?: boolean
  options?: Array<{ label: string; action: () => void }>
  value: any[]
  onChangeValue: (nextValue: any[]) => void | Promise<void>
}

export const ListField: React.FunctionComponent<ListFieldProps &
  EditorFormFieldProps> = ({
  values,
  value = [],
  documentRef,
  onChangeValue,
  label,
  types,
  typeKey = 'type',
  getItemLabel,
  oneOfEach,
}) => {
  const valueWithKeys = useMemo(
    () => value.map(v => ({ ...v, draggableId: uuid() })),
    [value]
  )

  const getType = (id?: string) => types.find(t => id === t.id) || null

  const availableTypes = oneOfEach
    ? types.filter(
        ({ id }) =>
          findIndex(value, { type: id } as {
            type: any
          }) === -1
      )
    : types

  const push = (valueToAdd: any) => {
    onChangeValue([...value, valueToAdd])
  }

  const removeFactory = (index: number) => () => {
    const nextValue = [...value]
    nextValue.splice(index, 1)
    onChangeValue(nextValue)
  }

  const updateFactory = (index: number) => (change: any) => {
    const nextValue = [...value]
    nextValue.splice(index, 1, change)
    onChangeValue(nextValue)
  }

  const onDragEnd = (e: DropResult) => {
    if (typeof e.destination?.index === 'number') {
      const nextValue = [...value]
      const [change] = nextValue.splice(e.source.index, 1)
      nextValue.splice(e.destination.index, 0, change)
      onChangeValue(nextValue)
    }
  }

  return (
    <StyledListField>
      <div className="dynamic-form-list__controls">
        <ControlAdd typeKey={typeKey} types={availableTypes} onAdd={push} />
      </div>
      {value.length === 0 ? (
        <EmptyState
          label={`No ${label.toLowerCase()} found, add one using the form above.`}
        />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {droppableProvided => (
              <div
                className="dynamic-form-list__contents"
                ref={droppableProvided.innerRef}
              >
                {valueWithKeys.map((field, index) => (
                  <ListFieldRow
                    name={field.name}
                    draggableId={field.draggableId}
                    index={index}
                    values={values}
                    documentRef={documentRef}
                    key={`list-row-${index}`}
                    spec={getType(get(field, typeKey))}
                    onChangeValue={updateFactory(index)}
                    onRemove={removeFactory(index)}
                    value={value[index]}
                    getItemLabel={getItemLabel}
                  />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </StyledListField>
  )
}
