import React, { FunctionComponent, useState } from 'react'

import { SelectionMenuMode } from '../types'

interface AddLinkModeProps {
  setMode: (mode: SelectionMenuMode) => void
  triggerOnChange: () => void
  range: Range
}

const AddLinkMode: FunctionComponent<AddLinkModeProps> = ({
  setMode,
  triggerOnChange,
  range,
}) => {
  const [urlValue, setUrlValue] = useState('')

  const handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const linkNode = document.createElement('a')
      linkNode.setAttribute('href', urlValue)
      range.surroundContents(linkNode)

      // onChange doesn't get called when we call "range.surroundContents" in
      // the main content-editable HTML element so we force it to trigger.
      triggerOnChange()
    }
  }

  return (
    <>
      <input
        autoFocus
        placeholder="link url..."
        onKeyPress={handleKeypress}
        value={urlValue}
        onChange={e => setUrlValue(e.target.value)}
      />
      <button onClick={() => setMode(SelectionMenuMode.Main)}>X</button>
    </>
  )
}

export default AddLinkMode
