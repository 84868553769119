import React, { FunctionComponent, useState } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import { rhythm } from '@humancollective/build-ui'

import { SyncStatus } from '../SyncStatus'
import { useSyncedValue } from '../../utils/useSyncedValue'
import { EditorFormFieldProps, EditorSyncStatus } from '../../types'

const SyledSyncedFormField = styled.div`
  margin-bottom: ${rhythm()}px;
`

export interface SyncedFormFieldProps extends EditorFormFieldProps {
  name: string
  Component: FunctionComponent<any>
}

export const SyncedFormField: FunctionComponent<SyncedFormFieldProps> = ({
  values,
  documentRef,
  name,
  Component,
  ...rest
}) => {
  const [focused, setFocused] = useState(false)

  const { status, value, setValue } = useSyncedValue({
    name,
    documentRef,
    initialValue: get(values, name),
  })

  return (
    <SyledSyncedFormField>
      <Component
        values={values}
        documentRef={documentRef}
        value={value}
        onChangeValue={setValue}
        focused={focused}
        setFocused={setFocused}
        {...rest}
      />
      <SyncStatus
        status={status}
        show={focused || status !== EditorSyncStatus.Live}
      />
    </SyledSyncedFormField>
  )
}
