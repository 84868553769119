import React from 'react'
import { useParams } from 'react-router-dom'
import firebase from 'firebase/app'

import ObjectEditorTemplate from '../../templates/ObjectEditorTemplate'

export interface EditAppRouteParams {
  dashboardId?: string
  appId: string
}

const EditApp: React.FunctionComponent = () => {
  const { appId } = useParams<EditAppRouteParams>()

  return (
    <ObjectEditorTemplate
      getQueryRef={() =>
        firebase
          .app('dashboard')
          .firestore()
          .collection('apps')
          .doc(appId)
      }
    />
  )
}

export default EditApp
