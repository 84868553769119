import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { BASE_RADIUS } from '../../theme'
import { rhythm } from '../../style/typography'

const StyledCard = styled.div<{ interactive: boolean }>`
  padding: ${rhythm()}px;
  border-radius: ${BASE_RADIUS * 4}px;
  background-color: white;
  margin: ${rhythm(1 / 2) - 2}px;
  min-width: ${rhythm(12)}px;
  flex: 1;
  box-shadow: 0 24px 32px #e3e6ec61;
  border: 1px solid #efefef;
`

export interface CardProps {
  className?: string
  onClick?: () => void
}

export const Card: FunctionComponent<CardProps> = ({
  className,
  onClick,
  children,
}) => (
  <StyledCard className={className} interactive={!!onClick} onClick={onClick}>
    {children}
  </StyledCard>
)
