import React, { FunctionComponent } from 'react'
import { useHistory } from 'react-router-dom'

import { TopThreeMetricCard } from '../MetricCard/TopThree'
import { TotalMetricCard } from '../MetricCard/Total'
import { CollectionCard } from '../CollectionCard'
import { FormRelayCard } from '../FormRelayCard'

import { StyledAppCard } from './style'
import { PostsCard } from '../PostsCard'
import { ExternalLinkCard } from '../LinkCard'
import { listCollectionRoute } from '../../../routes/collection'
import { viewMetricRoute } from '../../../routes/metric'
import { listPostsRoute } from '../../../routes/posts'
import { listFormResponsesRoute } from '../../../routes/forms'

interface AppCardProps {
  app: any
  onRemove?: () => void | Promise<void>
  dashboardId?: string
}

export const AppCard: FunctionComponent<AppCardProps> = ({
  app,
  dashboardId,
  onRemove,
}) => {
  const history = useHistory()
  return (
    <StyledAppCard>
      {onRemove && (
        <div className="app-card__remove" onClick={onRemove}>
          &ndash;
        </div>
      )}
      {app.appType === 'metric' && app.type === 'TOP_THREE' && (
        <TopThreeMetricCard
          key={app.id}
          metric={app}
          onClick={() => history.push(viewMetricRoute({ dashboardId, metricId: app.id }))}
        />
      )}
      {app.appType === 'metric' && app.type !== 'TOP_THREE' && (
        <TotalMetricCard key={app.id} metric={app} />
      )}
      {app.appType === 'collection' && (
        <CollectionCard
          key={app.id}
          collection={app}
          onClick={() =>
            history.push(
              listCollectionRoute({
                dashboardId,
                collectionId: app.id,
              })
            )
          }
        />
      )}
      {app.appType === 'posts' && (
        <PostsCard
          key={app.id}
          collection={app}
          onClick={() => history.push(listPostsRoute({
            dashboardId,
            collectionId: app.id,
          }))}
        />
      )}
      {app.appType === 'externalLink' && (
        <ExternalLinkCard
          key={app.id}
          action={app}
          onClick={() => window.open(app.url)}
        />
      )}
      {app.appType === 'form-relay' && (
        <FormRelayCard
          key={app.account}
          label={app.label}
          onClick={() => history.push(listFormResponsesRoute({
            dashboardId,
            accountId: app.account,
          }))}
        />
      )}
    </StyledAppCard>
  )
}
