import React from 'react'
import firebase from 'firebase/app'
import { Button, ListRow } from '@humancollective/build-ui'

import { useHistory, useParams } from 'react-router-dom'
import { ListTemplate } from '../../templates/ListTemplate'
import { useAuthedCollection } from '@humancollective/build-firebase'
import { createApp } from '../../callables/onCreateApp'
import { editAppRoute } from './router'

export interface ListAppsRouteParams {
  dashboardId: string
}

const AppList: React.FunctionComponent = () => {
  const { dashboardId } = useParams<ListAppsRouteParams>()
  const history = useHistory()

  const addItem = async () => {
    const appId = await createApp({ label: 'Untitled' })
    history.push(editAppRoute({ dashboardId, appId }))
  }

  const data = useAuthedCollection(() =>
    firebase.app('dashboard').firestore().collection('apps')
  )

  return data ? (
    <ListTemplate
      data={data}
      controls={<Button onClick={addItem}>Add</Button>}
      renderItem={(doc: any) => (
        <ListRow
          key={doc.id}
          label={doc.label}
          subtitle={doc.firebaseConfig?.projectId}
          onClick={() =>
            history.push(editAppRoute({ dashboardId, appId: doc.id }))
          }
        />
      )}
    />
  ) : null
}

export default AppList
