import React from 'react'
import styled from 'styled-components'

import { LoadSpinner } from './LoadSpinner'
export * from './LoadSpinner'

const StyledLoading = styled.div`
  background-color: #f8f9fb;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
`

export const Loading: React.FunctionComponent = () => (
  <StyledLoading>
    <LoadSpinner />
  </StyledLoading>
)
